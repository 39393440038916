import TextField from "@mui/material/TextField";

const CustomDateField = ({ value, min, max, required, disabled }) => (
  <TextField
    type="date"
    value={value ?? ""}
    required={required}
    disabled={disabled}
    min={min}
    max={max}
    variant="outlined"
    InputLabelProps={{ shrink: true }}
  />
);

export default CustomDateField;
