import TextField from "@mui/material/TextField";

const CustomInteger = ({
  value,
  min,
  max,
  disabled,
  required,
  placeholder,
}) => (
  <TextField
    type="number"
    variant="outlined"
    value={value}
    disabled={disabled}
    required={required}
    placeholder={placeholder}
    inputProps={{ min: Number(min), max: Number(max) }}
  />
);

export default CustomInteger;
