export const allowedFunctions = {
  // Logging
  consoleLog: console.log,

  // Math functions
  abs: Math.abs,
  acos: Math.acos,
  asin: Math.asin,
  atan: Math.atan,
  atan2: Math.atan2,
  ceil: Math.ceil,
  cos: Math.cos,
  exp: Math.exp,
  floor: Math.floor,
  log: Math.log,
  max: (array) => Math.max(...array),
  min: (array) => Math.min(...array),
  pow: Math.pow,
  random: Math.random,
  round: Math.round,
  sin: Math.sin,
  sqrt: Math.sqrt,
  tan: Math.tan,

  // String manipulation functions
  charAt: (str, index) => str.charAt(index),
  concat: (str, ...args) => str.concat(...args),
  includes: (str, searchString) => str.includes(searchString),
  indexOf: (str, searchString) => str.indexOf(searchString),
  lastIndexOf: (str, searchString) => str.lastIndexOf(searchString),
  slice: (str, start, end) => str.slice(start, end),
  split: (str, separator) => str.split(separator),
  substring: (str, start, end) => str.substring(start, end),
  toLowerCase: (str) => str.toLowerCase(),
  toUpperCase: (str) => str.toUpperCase(),
  trim: (str) => str.trim(),

  // Array manipulation functions
  push: (arr, item) => arr.push(item),
  pop: (arr) => arr.pop(),
  shift: (arr) => arr.shift(),
  unshift: (arr, item) => arr.unshift(item),
  concatArray: (arr, ...args) => arr.concat(...args),
  join: (arr, separator) => arr.join(separator),
  reverse: (arr) => arr.reverse(),
  splice: (arr, start, deleteCount, ...items) =>
    arr.splice(start, deleteCount, ...items),
  forEach: (arr, callback) => arr.forEach(callback),
  map: (arr, callback) => arr.map(callback),
  filter: (arr, callback) => arr.filter(callback),
  reduce: (arr, callback, initialValue) => arr.reduce(callback, initialValue),
  some: (arr, callback) => arr.some(callback),
  every: (arr, callback) => arr.every(callback),
  find: (arr, callback) => arr.find(callback),
  findIndex: (arr, callback) => arr.findIndex(callback),
  includesArray: (arr, value) => arr.includes(value),
  indexOfArray: (arr, value) => arr.indexOf(value),
  lastIndexOfArray: (arr, value) => arr.lastIndexOf(value),
  sliceArray: (arr, begin, end) => arr.slice(begin, end),

  // Object manipulation functions
  hasOwnProperty: (obj, prop) => obj.hasOwnProperty(prop),
  keys: Object.keys,
  values: Object.values,
  entries: Object.entries,
  assign: (target, ...sources) => Object.assign(target, ...sources),
};
