import React from "react";
import {
  Paper,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Draggable from "react-draggable";

const OverlayComponent = ({ children, title, onClose, onCancel, onOk }) => {
  return (
    <Draggable handle="#drag-handle">
      <Paper
        elevation={3}
        style={{
          display: "inline-block", // This ensures the Paper only takes as much width as needed
          minWidth: "200px",  // Optional, but helps prevent the Paper from getting too small
          maxWidth: "100%", // Optional, but helps prevent the Paper from getting too large
          position: "absolute",
          userSelect: "none",
          zIndex: 10,
        }}
      >
        <AppBar
          id="drag-handle"
          position="static"
          style={{ cursor: "grab", backgroundColor: "grey", height: "40px" }}
        >
          <Toolbar
            style={{
              minHeight: "40px",
              paddingLeft: "8px",
              paddingRight: "8px",
            }}
          >
            <Typography
              variant="subtitle1"
              style={{ flexGrow: 1, margin: "0 8px", fontSize: "1rem" }}
            >
              {title}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={onClose}
              aria-label="close"
              style={{ margin: "0 4px", padding: "4px" }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div style={{ padding: "16px" }}>{children}</div>
        <Divider />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "8px",
          }}
        >
          <Button
            onClick={onCancel}
            variant="outlined"
            style={{ marginRight: "8px" }}
          >
            Cancel
          </Button>
          <Button onClick={onOk} variant="contained" color="primary">
            OK
          </Button>
        </div>
      </Paper>
    </Draggable>
  );
};

export default OverlayComponent;
