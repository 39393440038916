import React from "react";
import Box from "@mui/system/Box";

import {
  JsonView,
  allExpanded,
  //   darkStyles,
  collapseAllNested,
  defaultStyles,
} from "react-json-view-lite";
import "react-json-view-lite/dist/index.css";

const ObjectPreview = (props) => {
  return (
    <Box
      sx={{
        overflow: "auto",
        maxHeight: "500px",
      }}
    >
      <JsonView
        data={props.json}
        shouldExpandNode={collapseAllNested}
        style={defaultStyles}
      />
      {/* <JsonView
        data={props.json}
        shouldExpandNode={allExpanded}
        style={darkStyles}
      /> */}
    </Box>
  );
};

export default ObjectPreview;
