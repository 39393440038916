import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  //   InputLabel,
  FormControl,
  Box,
  Typography,
} from "@mui/material";

const RuleEditor = ({ components, activeRule, handleActiveRuleUpdate }) => {
  const decodeHtmlEntity = (str) => {
    switch (str) {
      case "&lt;":
        return "<";
      case "&gt;":
        return ">";
      case "&lt;=":
        return "<=";
      case "&gt;=":
        return ">=";
      case "!=":
        return "!=";
      case "=":
        return "=";
      default:
        return str; // If no match, return the original string
    }
  };
  //   const [ruleName, setRuleName] = useState("");
  //   const [rows, setRows] = useState([
  //     {
  //       type: "attribute",
  //       componentId: "",
  //       key: "",
  //       value: "",
  //       operator: "IF",
  //       attributeSign: "",
  //     },
  //   ]);
  //   const [cellBehavior, setCellBehavior] = useState("");
  //   const [elseBehavior, setElseBehavior] = useState("");
  // const [componentIds, setComponentIds] = useState([]);
  const extractComponentKeys = () => {
    const keys = {};
    Object.keys(components).forEach((id) => {
      keys[id] = Object.keys(components[id].props);
    });
    return keys;
  };
  const [componentKeys, setComponentKeys] = useState(extractComponentKeys());
  //   const [affectedComponent, setAffectedComponent] = useState("");

  useEffect(() => {
    // setComponentIds(Object.keys(components));
    const keys = {};
    Object.keys(components).forEach((id) => {
      keys[id] = Object.keys(components[id].props);
    });
    setComponentKeys(keys);
  }, [components]);

  const addRow = (index) => {
    const newRow = {
      type: "attribute",
      componentId: "",
      key: "",
      value: "",
      operator: "OR",
      attributeSign: "",
    };
    const newRows = [...activeRule.rows];
    newRows.splice(index + 1, 0, newRow);
    handleActiveRuleUpdate("rows", newRows);
  };

  const updateRow = (index, changes) => {
    const newRows = [...activeRule.rows];
    newRows[index] = { ...newRows[index], ...changes };
    handleActiveRuleUpdate("rows", newRows);
  };

  const deleteRow = (index) => {
    const newRows = [...activeRule.rows];
    newRows.splice(index, 1);
    handleActiveRuleUpdate("rows", newRows);
  };

  return (
    <div>
      <TextField
        label="Rule Name"
        value={activeRule.ruleName}
        onChange={(e) => handleActiveRuleUpdate("ruleName", e.target.value)}
      />
      <TextField
        sx={{
          margin: "10px",
          "& .MuiInputBase-root": {
            backgroundColor: componentKeys[activeRule.affectedComponent]?.length
              ? "lightgreen"
              : "inherit",
          },
        }}
        value={activeRule.affectedComponent}
        label="Affected component's ID"
        onChange={(e) =>
          handleActiveRuleUpdate("affectedComponent", e.target.value)
        }
        margin="dense"
      />
      <br />
      {activeRule.rows.map((row, index) => (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          margin={1}
          key={index}
        >
          {index === 0 ? (
            <span>{row.operator}</span>
          ) : (
            <Select
              value={row.operator}
              onChange={(e) => updateRow(index, { operator: e.target.value })}
              margin="dense"
            >
              <MenuItem value={"AND"}>AND</MenuItem>
              <MenuItem value={"OR"}>OR</MenuItem>
            </Select>
          )}
          <Select
            value={row.type}
            onChange={(e) => updateRow(index, { type: e.target.value })}
            sx={{ margin: "10px" }}
            margin="dense"
          >
            <MenuItem value={"attribute"}>Attribute Value</MenuItem>
            <MenuItem value={"javascript"}>Javascript</MenuItem>
          </Select>
          {row.type === "attribute" ? (
            <>
              <TextField
                sx={{
                  margin: "10px",
                  "& .MuiInputBase-root": {
                    backgroundColor: componentKeys[row.componentId]?.length
                      ? "lightgreen"
                      : "inherit",
                  },
                }}
                value={row.componentId}
                label="Component ID"
                onChange={(e) =>
                  updateRow(index, { componentId: e.target.value })
                }
                margin="dense"
              />
              <Select
                sx={{ margin: "10px" }}
                value={row.key}
                onChange={(e) => updateRow(index, { key: e.target.value })}
                margin="dense"
              >
                {(componentKeys[row.componentId] || []).map((key) => (
                  <MenuItem key={key} value={key}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
              <Select
                label="Operator"
                value={decodeHtmlEntity(row.attributeSign)}
                onChange={(e) => {
                  // console.log(e.target.value);
                  updateRow(index, { attributeSign: e.target.value });
                }}
                sx={{ margin: "10px" }}
                margin="dense"
              >
                <MenuItem value={"="}>{`=`}</MenuItem>
                <MenuItem value={"<"}>{`<`}</MenuItem>
                <MenuItem value={">"}>{`>`}</MenuItem>
                <MenuItem value={"<="}>{`<=`}</MenuItem>
                <MenuItem value={">="}>{`>=`}</MenuItem>
                <MenuItem value={"!="}>{`!=`}</MenuItem>
              </Select>
              <TextField
                value={row.value}
                // placeholder="Value"
                label="Value"
                onChange={(e) => updateRow(index, { value: e.target.value })}
                margin="dense"
              />
            </>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              //   alignItems="center"
              justifyContent="center"
            >
              <TextField
                multiline
                rows={3}
                value={row.value}
                placeholder="Enter JS code"
                onChange={(e) => updateRow(index, { value: e.target.value })}
                margin="dense"
              />
              <Typography>
                Make sure your script returns a boolean value for the rule to
                work as expected.
              </Typography>
            </Box>
          )}
          <Button
            sx={{ margin: "10px" }}
            variant="outlined"
            onClick={() => addRow(index)}
          >
            Add Rule
          </Button>
          {index !== 0 && (
            <Button
              sx={{ margin: "10px" }}
              variant="outlined"
              onClick={() => deleteRow(index)}
            >
              Delete
            </Button>
          )}
        </Box>
      ))}
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <FormControl
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography sx={{ margin: "10px" }}>The cell is</Typography>
          <Select
            value={activeRule.cellBehavior}
            onChange={(e) =>
              handleActiveRuleUpdate("cellBehavior", e.target.value)
            }
          >
            <MenuItem value={"read-only"}>Read Only</MenuItem>
            <MenuItem value={"hidden"}>Hidden</MenuItem>
            <MenuItem value={"required"}>Required</MenuItem>
            <MenuItem value={"editable"}>Editable</MenuItem>
          </Select>
        </FormControl>
        <FormControl
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography sx={{ margin: "10px" }}>Else, the cell is</Typography>
          <Select
            value={activeRule.elseBehavior}
            onChange={(e) =>
              handleActiveRuleUpdate("elseBehavior", e.target.value)
            }
          >
            <MenuItem value={"read-only"}>Read Only</MenuItem>
            <MenuItem value={"hidden"}>Hidden</MenuItem>
            <MenuItem value={"required"}>Required</MenuItem>
            <MenuItem value={"editable"}>Editable</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </div>
  );
};

export default RuleEditor;
