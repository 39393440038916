import {
  TextField,
  Switch,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  InputLabel,
  FormControl,
  Button,
  Box,
  Checkbox,
  FormGroup,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { cloneDeep } from "lodash";
import { useState, forwardRef, useImperativeHandle } from "react";
import { languageList } from "../Constants";
import CopyToClipboardButton from "../helperComponents/CopyToClipboardButton";

const CheckboxEdit = forwardRef(({ component }, ref) => {
  const [localProps, setLocalProps] = useState(component.props);
  const [checkboxValues, setCheckboxValues] = useState(component.props.value);

  useImperativeHandle(ref, () => ({
    getProps: () => ({ localProps, componentId: component.id }),
  }));

  const handleChange = (type, value) => {
    const newProps = cloneDeep(localProps);
    if (type.includes(".")) {
      const splitType = type.split(".");
      newProps.label[splitType[1]] = value;
      setLocalProps(newProps);
      return;
    }
    switch (type) {
      case "XPATH":
        newProps.XPATH = value;
        break;
      case "disabled":
        newProps.disabled = value;
        break;
      case "required":
        newProps.required = value;
        break;
      case "invisible":
        newProps.invisible = value;
        break;
      default:
        return;
    }
    setLocalProps(newProps);
  };

  const handleCheckboxChange = (optionValue) => {
    let newValues = cloneDeep(checkboxValues);
    if (newValues.includes(optionValue)) {
      newValues = newValues.filter((val) => val !== optionValue);
    } else {
      newValues.push(optionValue);
    }
    setCheckboxValues(newValues);
    const newProps = cloneDeep(localProps);
    newProps.value = newValues;
    setLocalProps(newProps);
  };

  const handleOptionChange = (optionIndex, type, value) => {
    const newProps = cloneDeep(localProps);
    if (type === "value") {
      newProps.options[optionIndex].value = value;
    } else if (type.includes(".")) {
      const splitType = type.split(".");
      newProps.options[optionIndex].label[splitType[1]] = value;
    }
    setLocalProps(newProps);
  };

  const addNewOption = () => {
    const newProps = cloneDeep(localProps);
    newProps.options.push({
      value: "",
      label: { ...languageList },
    });
    setLocalProps(newProps);
  };

  const handleDeleteOption = (optionIndex) => {
    const newProps = cloneDeep(localProps);
    if (newProps.options[optionIndex].value === newProps.value) {
      const idx = checkboxValues.indexOf(newProps.options[optionIndex].value);
      if (idx > -1) checkboxValues.splice(idx, 1);
      setCheckboxValues(checkboxValues);
      newProps.value = checkboxValues;
    }
    newProps.options.splice(optionIndex, 1);
    setLocalProps(newProps);
  };

  return (
    <div style={{ width: "100%", overflow: "auto" }}>
      <TextField
        sx={{ borderRadius: 0 }}
        label="XPATH"
        value={localProps.XPATH}
        onChange={(e) => handleChange("XPATH", e.target.value)}
        fullWidth
      />
      <CopyToClipboardButton textToCopy={component.id} />
      <FormControlLabel
        control={
          <Switch
            checked={localProps.disabled}
            onChange={(e) => handleChange("disabled", e.target.checked)}
          />
        }
        label="Disabled"
      />
      <FormControlLabel
        control={
          <Switch
            checked={localProps.required}
            onChange={(e) => handleChange("required", e.target.checked)}
          />
        }
        label="Required"
      />
      <FormControlLabel
        control={
          <Switch
            checked={localProps.invisible}
            onChange={(e) => handleChange("invisible", e.target.checked)}
          />
        }
        label="Invisible"
      />
      {/* <TextField
          sx={{ borderRadius: 0 }}
          label="Value"
          value={localProps.value}
          onChange={(e) => handleChange("value", e.target.value)}
          fullWidth
          inputProps={{ maxLength: localProps.maxLength }}
        />
        <TextField
          sx={{ borderRadius: 0 }}
          label="Placeholder"
          value={localProps.placeholder}
          onChange={(e) => handleChange("placeholder", e.target.value)}
          fullWidth
        /> */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Active values</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: "column" }}>
          <FormGroup>
            {localProps.options.map((option, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={checkboxValues.includes(option.value)}
                    onChange={() => handleCheckboxChange(option.value)}
                  />
                }
                label={option.value}
              />
            ))}
          </FormGroup>
        </AccordionDetails>
      </Accordion>
      {/* <FormControl fullWidth component="fieldset">
        <InputLabel shrink={true} component="legend">
          Choose options
        </InputLabel>
        <FormGroup>
          {localProps.options.map((option, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={checkboxValues.includes(option.value)}
                  onChange={() => handleChange("value", option.value)}
                />
              }
              label={option.value}
            />
          ))}
        </FormGroup>
      </FormControl> */}

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Options</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: "column" }}>
          <FormGroup>
            {localProps.options.map((option, index) => (
              <Box key={index}>
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkboxValues.includes(option.value)}
                      onChange={() => handleCheckboxChange(option.value)}
                    />
                  }
                  label={option.value}
                /> */}
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{option.value}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TextField
                      label="Option value"
                      value={option.value}
                      onChange={(e) =>
                        handleOptionChange(index, "value", e.target.value)
                      }
                    />
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Languages</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {Object.entries(option.label).map(([lang, text]) => (
                          <TextField
                            key={lang}
                            label={`Option Label (${lang})`}
                            value={text}
                            onChange={(e) =>
                              handleOptionChange(
                                index,
                                `label.${lang}`,
                                e.target.value
                              )
                            }
                          />
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  </AccordionDetails>
                </Accordion>
                {/* //... [Other inputs related to this option] */}
                <Button
                  onClick={() => handleDeleteOption(index)}
                  fullWidth
                  variant="contained"
                  color="secondary"
                  style={{ margin: "10px 0" }}
                >
                  Delete Option
                </Button>
              </Box>
            ))}
          </FormGroup>
        </AccordionDetails>
        <Button fullWidth variant="outlined" onClick={addNewOption}>
          Add New Option
        </Button>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          // sx={{
          // //   "&.MuiAccordionSummary-root": {
          // //     margin: "20px", // you can adjust this as needed
          // //     minHeight: "initial", // to overwrite the min height if it's the case
          // //   },
          //   "&.Mui-expanded": {
          //     minHeight: "initial", // to overwrite the min height when expanded
          //   },
          // //   "& .MuiAccordionSummary-content": {
          // //     margin: 0, // again, adjust this as needed
          // //   },
          //   "&.Mui-expanded .MuiAccordionSummary-content": {
          //     margin: 0, // this is when the summary is in expanded state
          //   },
          // }}
          // style={{ padding: 0, margin: 0 }}
        >
          <Typography>Languages</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: "column" }}>
          {Object.entries(localProps.label).map(([lang, text]) => (
            <TextField
              sx={{ borderRadius: 0 }}
              key={lang}
              label={`Label (${lang})`}
              value={text}
              onChange={(e) => handleChange(`label.${lang}`, e.target.value)}
              fullWidth
            />
          ))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
});

export default CheckboxEdit;
