import React from "react";
import { useDrop } from "react-dnd";
import { COMPONENT, SIDEBAR_ITEM, ROW, COLUMN } from "../Constants";
import { Box } from "@mui/material";

const ACCEPTS = [SIDEBAR_ITEM, COMPONENT, ROW, COLUMN];

const DropZone = ({ data, onDrop, isLast }) => {
  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: ACCEPTS,
      drop: (item, monitor) => {
        // console.log(
        //   "ok, inside of the dropzone, about to call the onDrop, here is the item passed to the dropzone: ",
        //   item
        // );

        onDrop(data, { ...item, type: monitor.getItemType() });
      },
      //have to fix canDrop, seems to be working wrong.
      canDrop: (item, monitor) => {
        const dropZonePath = data.path;
        const splitDropZonePath = dropZonePath.split("-");
        const itemPath = item.path;

        // sidebar items can always be dropped anywhere
        if (!itemPath) {
          // if (data.childrenCount >= 3) {
          //  return false;
          // }
          return true;
        }

        const splitItemPath = itemPath.split("-");

        // limit columns when dragging from one row to another row
        const dropZonePathRowIndex = splitDropZonePath[0];
        const itemPathRowIndex = splitItemPath[0];
        const diffRow = dropZonePathRowIndex !== itemPathRowIndex;
        if (
          diffRow &&
          splitDropZonePath.length === 2 &&
          data.childrenCount >= 3
        ) {
          return false;
        }

        // Invalid (Can't drop a parent element (row) into a child (column))
        const parentDropInChild =
          splitItemPath.length < splitDropZonePath.length;
        if (parentDropInChild) return false;

        // Current item can't possible move to it's own location
        if (itemPath === dropZonePath) return false;

        // Current area
        if (splitItemPath.length === splitDropZonePath.length) {
          const pathToItem = splitItemPath.slice(0, -1).join("-");
          const currentItemIndex = Number(splitItemPath.slice(-1)[0]);

          const pathToDropZone = splitDropZonePath.slice(0, -1).join("-");
          const currentDropZoneIndex = Number(splitDropZonePath.slice(-1)[0]);

          if (pathToItem === pathToDropZone) {
            const nextDropZoneIndex = currentItemIndex + 1;
            if (nextDropZoneIndex === currentDropZoneIndex) return false;
          }
        }

        return true;
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [data]
  );

  const isActive = isOver && canDrop;
  return (
    <Box
      className={`dropZone ${isActive ? "active" : ""} ${
        isLast ? "isLast" : ""
      }`}
      ref={drop}
      sx={{
        bgcolor: isActive ? "primary.main" : "background.default",
        color: isActive ? "primary.contrastText" : "text.primary",
        border: isActive ? 2 : 1,
        borderColor: "divider",
        // borderRadius: 1,
        m: 1,
        p: 1,
        transition: "0.3s",
      }}
    >
      {/* {data.path} */}
    </Box>
  );
};
export default DropZone;
