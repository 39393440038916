import { v4 as uuid } from "uuid";

export const SIDEBAR_ITEM = "sidebarItem";
export const ROW = "row";
export const COLUMN = "column";
export const COMPONENT = "component";

export const IMAGE_TYPES = [
  {
    id: "image/*",
    name: "All image types",
    category: "image",
    exclusive: true,
  },
  { id: "image/bmp", name: "BMP", category: "image" },
  { id: "image/gif", name: "GIF", category: "image" },
  { id: "image/vnd.microsoft.icon", name: "ICO", category: "image" },
  { id: "image/jpeg", name: "JPEG", category: "image" },
  { id: "image/png", name: "PNG", category: "image" },
  { id: "image/svg+xml", name: "SVG", category: "image" },
  { id: "image/webp", name: "WebP", category: "image" },
];

export const VIDEO_TYPES = [
  {
    id: "video/*",
    name: "All video types",
    category: "video",
    exclusive: true,
  },
  { id: "video/mp4", name: "MP4", category: "video" },
  { id: "video/mpeg", name: "MPEG", category: "video" },
  { id: "video/ogg", name: "OGG", category: "video" },
  { id: "video/webm", name: "WebM", category: "video" },
  { id: "video/3gpp", name: "3GP", category: "video" },
  { id: "video/3gpp2", name: "3G2", category: "video" },
];

export const AUDIO_TYPES = [
  {
    id: "audio/*",
    name: "All audio types",
    category: "audio",
    exclusive: true,
  },
  { id: "audio/aac", name: "AAC", category: "audio" },
  { id: "audio/midi", name: "MIDI", category: "audio" },
  { id: "audio/x-midi", name: "X-MIDI", category: "audio" },
  { id: "audio/mpeg", name: "MP3", category: "audio" },
  { id: "audio/ogg", name: "OGG", category: "audio" },
  { id: "audio/opus", name: "Opus", category: "audio" },
  { id: "audio/wav", name: "WAV", category: "audio" },
  { id: "audio/webm", name: "WebM", category: "audio" },
];

export const EXT_TYPES = [
  { id: "application/pdf", name: "PDF", category: ".ext", exclusive: true },
  {
    id: "application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    name: "Word",
    category: ".ext",
  },
  {
    id: "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    name: "Excel",
    category: ".ext",
  },
  {
    id: "application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation",
    name: "Powerpoint",
    category: ".ext",
  },
  { id: "text/plain", name: "Text", category: ".ext" },
  { id: "text/csv", name: "CSV", category: ".ext" },
  { id: "application/xml", name: "XML", category: ".ext" },
  { id: "application/json", name: "JSON", category: ".ext" },
];

export const FILE_TYPES = [
  {
    id: "image/*",
    name: "All image types",
    category: "image",
    exclusive: true,
  },
  { id: "image/bmp", name: "BMP", category: "image" },
  { id: "image/gif", name: "GIF", category: "image" },
  { id: "image/vnd.microsoft.icon", name: "ICO", category: "image" },
  { id: "image/jpeg", name: "JPEG", category: "image" },
  { id: "image/png", name: "PNG", category: "image" },
  { id: "image/svg+xml", name: "SVG", category: "image" },
  { id: "image/webp", name: "WebP", category: "image" },
  {
    id: "video/*",
    name: "All video types",
    category: "video",
    exclusive: true,
  },
  { id: "video/mp4", name: "MP4", category: "video" },
  { id: "video/mpeg", name: "MPEG", category: "video" },
  { id: "video/ogg", name: "OGG", category: "video" },
  { id: "video/webm", name: "WebM", category: "video" },
  { id: "video/3gpp", name: "3GP", category: "video" },
  { id: "video/3gpp2", name: "3G2", category: "video" },
  {
    id: "audio/*",
    name: "All audio types",
    category: "audio",
    exclusive: true,
  },
  { id: "audio/aac", name: "AAC", category: "audio" },
  { id: "audio/midi", name: "MIDI", category: "audio" },
  { id: "audio/x-midi", name: "X-MIDI", category: "audio" },
  { id: "audio/mpeg", name: "MP3", category: "audio" },
  { id: "audio/ogg", name: "OGG", category: "audio" },
  { id: "audio/opus", name: "Opus", category: "audio" },
  { id: "audio/wav", name: "WAV", category: "audio" },
  { id: "audio/webm", name: "WebM", category: "audio" },
  { id: "application/pdf", name: "PDF", category: ".ext", exclusive: true },
  {
    id: "application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    name: "Word",
    category: ".ext",
  },
  {
    id: "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    name: "Excel",
    category: ".ext",
  },
  {
    id: "application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation",
    name: "Powerpoint",
    category: ".ext",
  },
  { id: "text/plain", name: "Text", category: ".ext" },
  { id: "text/csv", name: "CSV", category: ".ext" },
  { id: "application/xml", name: "XML", category: ".ext" },
  { id: "application/json", name: "JSON", category: ".ext" },
  // Add more file types here if needed
];

export const languageList = {
  EN: "",
  ES: "",
  ZH: "",
  LV: "",
  HI: "",
  FR: "",
  AR: "",
  RU: "",
  PT: "",
  DE: "",
  JP: "",
  TR: "",
};

export const DEFAULT_PROPS = {
  "text-field": {
    label: { ...languageList, EN: "Text Field" },
    placeholder: "Enter text...",
    value: "",
    disabled: false,
    required: false,
    invisible: false,
    errorMessage: "",
    maxLength: "200", // maximum length for the input
    XPATH: "/textfield",
  },
  "file-upload": {
    label: { ...languageList, EN: "File-Upload" },
    accept: "image/*,application/pdf", // files accepted
    multiple: false,
    disabled: false,
    required: false,
    invisible: false,
    maxSize: "5000000", // Max file size in bytes
    errorMessage: "",
    XPATH: "/fileupload",
  },
  "file-reference": {
    label: { ...languageList, EN: "File-Reference Link" },
    value: "", // reference to the file
    placeholder: "Enter the link...",
    disabled: false,
    required: false,
    invisible: false,
    errorMessage: "",
    XPATH: "/filereference",
  },
  "date-field": {
    label: { ...languageList, EN: "Date Field" },
    value: "", // Date string in 'YYYY-MM-DD' format
    min: "", // Minimum date allowed
    max: "", // Maximum date allowed
    disabled: false,
    required: false,
    invisible: false,
    errorMessage: "",
    XPATH: "/datefield",
  },
  "radio-group": {
    label: { ...languageList, EN: "Radio-Group" },
    options: [
      { value: "option1", label: { ...languageList, EN: "Option 1" } },
      { value: "option2", label: { ...languageList, EN: "Option 2" } },
      { value: "option3", label: { ...languageList, EN: "Option 3" } },
    ],
    value: "option1", // Initially selected value
    disabled: false,
    required: false,
    invisible: false,
    errorMessage: "",
    XPATH: "/radiogroup",
  },
  select: {
    label: { ...languageList, EN: "Select" },
    options: [
      { value: "option1", label: { ...languageList, EN: "Option 1" } },
      { value: "option2", label: { ...languageList, EN: "Option 2" } },
      { value: "option3", label: { ...languageList, EN: "Option 3" } },
    ],
    value: "option1", // Initially selected value
    disabled: false,
    required: false,
    invisible: false,
    errorMessage: "",
    XPATH: "/select",
  },
  checkbox: {
    label: { ...languageList, EN: "Checkbox" },
    options: [
      { value: "option1", label: { ...languageList, EN: "Option 1" } },
      { value: "option2", label: { ...languageList, EN: "Option 2" } },
      { value: "option3", label: { ...languageList, EN: "Option 3" } },
    ],
    value: ["option1"], // Initially selected value
    disabled: false,
    required: false,
    invisible: false,
    errorMessage: "",
    XPATH: "/checkbox",
  },
  "custom-html": {
    label: { ...languageList, EN: "Custom HTML" },
    value: "", // Initially selected value
    disabled: false,
    required: false,
    invisible: false,
    errorMessage: "",
    XPATH: "/htmlcustom",
  },
  paragraph: {
    label: { ...languageList, EN: "Paragraph" },
    value: "", // Text content of the paragraph
    placeholder: "Enter text...",
    disabled: false,
    required: false,
    invisible: false,
    maxLength: "1000",
    errorMessage: "",
    XPATH: "/paragraph",
  },
  integer: {
    label: { ...languageList, EN: "Integer" },
    value: "", // Initial value
    min: "", // Minimum value allowed
    max: "", // Maximum value allowed
    disabled: false,
    required: false,
    invisible: false,
    placeholder: "Enter some value...",
    errorMessage: "",
    XPATH: "/integer",
  },
  float: {
    label: { ...languageList, EN: "Float" },
    value: "", // Initial value
    min: "", // Minimum value allowed
    max: "", // Maximum value allowed
    precision: "2", // Number of decimal places
    placeholder: "Enter some value...",
    disabled: false,
    required: false,
    invisible: false,
    errorMessage: "",
    XPATH: "/float",
  },
  "template-reference": {
    label: { ...languageList, EN: "Template reference" },
    placeholder: "Enter text...",
    templateId: -1,
    templateName: "",
    disabled: false,
    required: false,
    invisible: false,
    errorMessage: "Invalid template reference",
    // maxLength: 200,
    XPATH: "/templatereference",
  },
  "file-attachment": {
    label: { ...languageList, EN: "File attachment" },
    // accept: "", // files accepted
    multiple: true,
    disabled: false,
    required: false,
    invisible: false,
    files: [],
    errorMessage: "",
    // maxSize: 5000000, // Max file size in bytes
    XPATH: "/file-attachment",
  },
  // "two-column-layout": {
  //   // Specific properties for the two-column layout can go here
  //   XPATH: "/twocolumnlayout",
  // },
  // "image-upload": {
  //   label: { ...languageList, EN: "Image-Upload" },
  //   accept: "image/*", // files accepted
  //   multiple: false,
  //   maxSize: 5000000, // Max file size in bytes
  //   XPATH: "/imageupload",
  // },
};

export const SIDEBAR_ITEMS = [
  {
    id: uuid(),
    type: SIDEBAR_ITEM,
    component: {
      type: "text-field",
      content: "Text Field",
      icon: "TextFieldIcon",
    },
  },
  {
    id: uuid(),
    type: SIDEBAR_ITEM,
    component: {
      type: "file-upload",
      content: "File Upload",
      icon: "FileUploadIcon",
    },
  },
  {
    id: uuid(),
    type: SIDEBAR_ITEM,
    component: {
      type: "file-reference",
      content: "File reference link",
      icon: "AddLinkIcon",
    },
  },
  {
    id: uuid(),
    type: SIDEBAR_ITEM,
    component: {
      type: "date-field",
      content: "Date",
      icon: "EventIcon",
    },
  },
  {
    id: uuid(),
    type: SIDEBAR_ITEM,
    component: {
      type: "radio-group",
      content: "Radio group",
      icon: "RadioButtonCheckedIcon",
    },
  },
  {
    id: uuid(),
    type: SIDEBAR_ITEM,
    component: {
      type: "select",
      content: "Select",
      icon: "ArrowDropDownIcon",
    },
  },
  {
    id: uuid(),
    type: SIDEBAR_ITEM,
    component: {
      type: "checkbox",
      content: "Checkbox",
      icon: "CheckBoxIcon",
    },
  },
  {
    id: uuid(),
    type: SIDEBAR_ITEM,
    component: {
      type: "paragraph",
      content: "Paragraph",
      icon: "SegmentIcon",
    },
  },
  {
    id: uuid(),
    type: SIDEBAR_ITEM,
    component: {
      type: "integer",
      content: "Integer",
      icon: "Looks3Icon",
    },
  },
  {
    id: uuid(),
    type: SIDEBAR_ITEM,
    component: {
      type: "float",
      content: "Float",
      icon: "NumbersIcon",
    },
  },
  {
    id: uuid(),
    type: SIDEBAR_ITEM,
    component: {
      type: "custom-html",
      content: "Custom HTML",
      icon: "CodeIcon",
    },
  },
  {
    id: uuid(),
    type: SIDEBAR_ITEM,
    component: {
      type: "template-reference",
      content: "Template reference",
      icon: "IntegrationInstructionsIcon",
    },
  },
  {
    id: uuid(),
    type: SIDEBAR_ITEM,
    component: {
      type: "file-attachment",
      content: "File attachment",
      icon: "AttachFileIcon",
    },
  },
  // {
  //   id: uuid(),
  //   type: SIDEBAR_ITEM,
  //   component: {
  //     type: "image-upload",
  //     content: "Image Upload",
  //     icon: "ImageIcon",
  //   },
  // },
];
