import TextField from "@mui/material/TextField";

const CustomFloat = ({
  value,
  min,
  max,
  precision,
  required,
  disabled,
  placeholder,
}) => {
  // console.log(
  //   value,
  //   min,
  //   max,
  //   "HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH"
  // );
  return (
    <TextField
      type="number"
      value={value}
      variant="outlined"
      required={required}
      disabled={disabled}
      placeholder={placeholder}
      step={1 / Math.pow(10, Number(precision))}
      inputProps={{ min: Number(min), max: Number(max) }}
    />
  );
};

export default CustomFloat;
