import TextField from "@mui/material/TextField";

const CustomParagraph = ({
  content,
  placeholder,
  required,
  disabled,
  maxLength,
}) => (
  <TextField
    multiline
    rows={4}
    placeholder={placeholder}
    variant="outlined"
    disabled={disabled}
    required={required}
    value={content}
    inputProps={{ maxLength: Number(maxLength) }}
    // defaultValue={content}
  />
);

export default CustomParagraph;
