import React from "react";

const PreviewFileAttachment = ({ files }) => {
  return (
    <div>
      <h3>Your files will be displayed here!</h3>
      {/* {files.length === 0 ? (
        <p>No files attached!</p>
      ) : (
        <ul>
          {files.map((file) => (
            <li key={file.id}>{file.fileName}</li>
          ))}
        </ul>
      )} */}
    </div>
  );
};

export default PreviewFileAttachment;
