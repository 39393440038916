import React, { useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const CustomSelect = ({ value, options, required, disabled }) => {
  // console.log(value, options, "IIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIII");
  // const [selectValue, setSelectValue] = useState(value); // Set an initial value

  const handleChange = (event) => {
    // setSelectValue(event.target.value);
  };

  // console.log(
  //   options,
  //   value,
  //   "THESE ARE THE OPTIONS AND THE VALUE BEING PRINTED FROM CUSTOMSELECT.JS"
  // );

  return (
    <FormControl variant="outlined" fullWidth>
      {/* <InputLabel htmlFor="custom-select">Select</InputLabel> */}
      <Select
        disabled={disabled}
        required={required}
        // label="Select"
        value={value} // Pass the value state here
        // onChange={handleChange}
        inputProps={{
          name: "custom-select",
          id: "custom-select",
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label.EN}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomSelect;
