import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import FileCopyIcon from "@mui/icons-material/FileCopy";

const CopyToClipboardButton = ({ textToCopy }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 1000); // Reset after 2 seconds
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <TextField
        label="Component ID"
        value={textToCopy}
        InputProps={{
          readOnly: true,
          style: {
            transition: "background-color 0.5s ease",
            backgroundColor: isCopied ? "lightgreen" : "inherit",
          },
          endAdornment: (
            <IconButton onClick={handleCopyClick}>
              <FileCopyIcon />
            </IconButton>
          ),
        }}
      />
    </div>
  );
};

export default CopyToClipboardButton;
