import {
  TextField,
  Switch,
  FormControlLabel,
  //   Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { cloneDeep } from "lodash";
import { useState, forwardRef, useImperativeHandle } from "react";
import { languageList } from "../Constants";
import CopyToClipboardButton from "../helperComponents/CopyToClipboardButton";

const SelectEdit = forwardRef(({ component }, ref) => {
  const [localProps, setLocalProps] = useState(component.props);
  const [selectValue, setSelectValue] = useState(component.props.value); // Set an initial value

  useImperativeHandle(ref, () => ({
    getProps: () => ({ localProps, componentId: component.id }),
  }));

  // const {
  //   label,
  //   placeholder,
  //   value,
  //   disabled,
  //   required,
  //   errorMessage,
  //   maxLength,
  //   XPATH,
  // } = component.props;

  const handleChange = (type, value) => {
    const newProps = cloneDeep(localProps);
    if (type.includes(".")) {
      const splitType = type.split(".");
      newProps.label[splitType[1]] = value;
      setLocalProps(newProps);
      return;
    }
    switch (type) {
      case "XPATH":
        newProps.XPATH = value;
        break;
      case "disabled":
        newProps.disabled = value;
        break;
      case "required":
        newProps.required = value;
        break;
      case "invisible":
        newProps.invisible = value;
        break;
      case "value":
        setSelectValue(value);
        newProps.value = value;
        break;
      default:
        return;
    }
    setLocalProps(newProps);
  };

  const handleOptionChange = (optionIndex, type, value) => {
    const newProps = cloneDeep(localProps);

    if (type === "value") {
      // If the option being updated is currently selected, update the selectValue and newProps.value too.
      if (newProps.options[optionIndex].value === selectValue) {
        setSelectValue(value);
        newProps.value = value; // Add this line to update the value of the select
      }

      newProps.options[optionIndex].value = value;
    } else if (type.includes(".")) {
      const splitType = type.split(".");
      newProps.options[optionIndex].label[splitType[1]] = value;
    }

    setLocalProps(newProps);
  };

  const addNewOption = () => {
    const newProps = cloneDeep(localProps);
    newProps.options.push({
      value: "",
      label: { ...languageList },
    });
    setLocalProps(newProps);
  };

  const handleDeleteOption = (optionIndex) => {
    const newProps = cloneDeep(localProps);
    let foundIndex = -1;
    if (newProps.options[optionIndex].value === newProps.value) {
      setSelectValue("");
      newProps.value = "";
    }
    newProps.options.splice(optionIndex, 1); // Remove the option
    setLocalProps(newProps);
  };

  // const handleDropdownChange = (e) => {
  //   setShowLanguages(e.target.value === "Show Languages");
  // };

  return (
    <div style={{ width: "100%", overflow: "auto" }}>
      <TextField
        sx={{ borderRadius: 0 }}
        label="XPATH"
        value={localProps.XPATH}
        onChange={(e) => handleChange("XPATH", e.target.value)}
        fullWidth
      />
      <CopyToClipboardButton textToCopy={component.id} />
      <FormControlLabel
        control={
          <Switch
            checked={localProps.disabled}
            onChange={(e) => handleChange("disabled", e.target.checked)}
          />
        }
        label="Disabled"
      />
      <FormControlLabel
        control={
          <Switch
            checked={localProps.required}
            onChange={(e) => handleChange("required", e.target.checked)}
          />
        }
        label="Required"
      />
      <FormControlLabel
        control={
          <Switch
            checked={localProps.invisible}
            onChange={(e) => handleChange("invisible", e.target.checked)}
          />
        }
        label="Invisible"
      />
      {/* <TextField
        sx={{ borderRadius: 0 }}
        label="Value"
        value={localProps.value}
        onChange={(e) => handleChange("value", e.target.value)}
        fullWidth
        inputProps={{ maxLength: localProps.maxLength }}
      />
      <TextField
        sx={{ borderRadius: 0 }}
        label="Placeholder"
        value={localProps.placeholder}
        onChange={(e) => handleChange("placeholder", e.target.value)}
        fullWidth
      /> */}
      <FormControl fullWidth>
        <InputLabel id="select-label">Select the active value</InputLabel>
        <Select
          labelId="select-label"
          fullWidth
          value={selectValue}
          onChange={(e) => handleChange("value", e.target.value)}
        >
          {localProps.options.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Options</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: "column" }}>
          {localProps.options.map((option, index) => (
            <Box sx={{ mb: "10px" }} key={index}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{option.value}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TextField
                    label="Option value"
                    value={option.value}
                    onChange={(e) =>
                      handleOptionChange(index, "value", e.target.value)
                    }
                  />
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>Languages</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {Object.entries(option.label).map(([lang, text]) => (
                        <TextField
                          key={lang}
                          label={`Option Label (${lang})`}
                          value={text}
                          onChange={(e) =>
                            handleOptionChange(
                              index,
                              `label.${lang}`,
                              e.target.value
                            )
                          }
                        />
                      ))}
                    </AccordionDetails>
                  </Accordion>
                </AccordionDetails>
              </Accordion>
              <Button
                onClick={() => handleDeleteOption(index)}
                sx={{ borderRadius: 0 }}
                fullWidth
                variant="contained"
                color="secondary"
                style={{ margin: "10px 0" }}
              >
                Delete Option
              </Button>
            </Box>
          ))}
        </AccordionDetails>
        <Button fullWidth variant="outlined" onClick={addNewOption}>
          Add New Option
        </Button>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          // sx={{
          // //   "&.MuiAccordionSummary-root": {
          // //     margin: "20px", // you can adjust this as needed
          // //     minHeight: "initial", // to overwrite the min height if it's the case
          // //   },
          //   "&.Mui-expanded": {
          //     minHeight: "initial", // to overwrite the min height when expanded
          //   },
          // //   "& .MuiAccordionSummary-content": {
          // //     margin: 0, // again, adjust this as needed
          // //   },
          //   "&.Mui-expanded .MuiAccordionSummary-content": {
          //     margin: 0, // this is when the summary is in expanded state
          //   },
          // }}
          // style={{ padding: 0, margin: 0 }}
        >
          <Typography>Languages</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: "column" }}>
          {Object.entries(localProps.label).map(([lang, text]) => (
            <TextField
              sx={{ borderRadius: 0 }}
              key={lang}
              label={`Label (${lang})`}
              value={text}
              onChange={(e) => handleChange(`label.${lang}`, e.target.value)}
              fullWidth
            />
          ))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
});

export default SelectEdit;
