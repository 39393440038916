import React, { useRef, useState } from "react";
import { useDrag } from "react-dnd";
import { COMPONENT } from "../Constants";
import CustomDateField from "../paletteComponents/CustomDateField";
import CustomTextField from "../paletteComponents/CustomTextField";
import CustomFileReference from "../paletteComponents/CustomFileReference";
import CustomFileUpload from "../paletteComponents/CustomFileUpload";
import CustomFloat from "../paletteComponents/CustomFloat";
// import CustomImageUpload from "../paletteComponents/CustomImageUpload";
import CustomInteger from "../paletteComponents/CustomInteger";
import CustomParagraph from "../paletteComponents/CustomParagraph";
import CustomRadioGroup from "../paletteComponents/CustomRadioGroup";
import CustomSelect from "../paletteComponents/CustomSelect";
import CustomCheckbox from "../paletteComponents/CustomCheckbox";
import HtmlCustom from "../paletteComponents/HtmlCustom";
import CustomTemplateReference from "../paletteComponents/CustomTemplateReference";
import CustomFileAttachment from "../paletteComponents/CustomFileAttachment";
// import TwoColumnLayout from "../paletteComponents/TwoColumnLayout";
import { styled } from "@mui/system";
import Paper from "@mui/material/Paper";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { DeleteButton } from "./DeleteButton";

const DraggablePaper = styled(Paper)(({ theme }) => ({
  border: "1px dashed black",
  padding: "0.5rem 1rem",
  cursor: "move",
  borderRadius: 0,
  backgroundColor: theme.palette.background.paper,
  "&.draggable": {
    // additional styles for draggable class
  },
}));

const Component = ({
  data,
  path,
  components,
  selectComponent,
  handleDelete,
}) => {
  const [deleteBtnPosition, setDeleteBtnPosition] = useState(null);
  const handleRightClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // Hide menu if it's already open
    if (deleteBtnPosition) {
      setDeleteBtnPosition(null);
      return;
    }

    // Get bounding box of the parent component
    const boundingBox = ref.current.getBoundingClientRect();

    const x = e.clientX - boundingBox.left; // No scrollX
    const y = e.clientY - boundingBox.top; // No scrollY

    setDeleteBtnPosition({ x, y });
  };

  const handleDeleteClick = () => {
    // Call your delete function here
    // After deleting, remove the delete button
    handleDelete(null, { path });
    setDeleteBtnPosition(null);
  };

  const ref = useRef(null);
  const component = components[data.id];

  const [{ isDragging }, drag] = useDrag({
    type: COMPONENT,
    item: () => ({
      id: data.id,
      path,
      variation: component.type,
    }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(ref);

  // component renderer
  let renderedComponent;
  // console.log(
  //   component.props,
  //   "THESE ARE THe PROPS FOR THE COMPONENT!!! INSIDE COMPONENT.JS"
  // );

  switch (component.type) {
    case "date-field":
      renderedComponent = <CustomDateField {...component.props} />;
      break;
    case "text-field":
      renderedComponent = <CustomTextField {...component.props} />;
      break;
    case "file-upload":
      renderedComponent = <CustomFileUpload {...component.props} />;
      break;
    case "file-reference":
      renderedComponent = <CustomFileReference {...component.props} />;
      break;
    case "radio-group":
      renderedComponent = <CustomRadioGroup {...component.props} />;
      break;
    case "select":
      renderedComponent = <CustomSelect {...component.props} />;
      break;
    case "checkbox":
      renderedComponent = <CustomCheckbox {...component.props} />;
      break;
    case "custom-html":
      renderedComponent = <HtmlCustom {...component.props} />;
      break;
    case "paragraph":
      renderedComponent = <CustomParagraph {...component.props} />;
      break;
    case "integer":
      renderedComponent = <CustomInteger {...component.props} />;
      break;
    case "float":
      renderedComponent = <CustomFloat {...component.props} />;
      break;
    case "template-reference":
      renderedComponent = <CustomTemplateReference {...component.props} />;
      break;
    case "file-attachment":
      renderedComponent = <CustomFileAttachment {...component.props} />;
      break;
    // case "two-column-layout":
    //   renderedComponent = <TwoColumnLayout {...component.props} />;
    //   break;
    // case "image-upload":
    //   renderedComponent = <CustomImageUpload {...component.props} />;
    //   break;
    default:
      renderedComponent = null;
  }

  return (
    <DraggablePaper
      onDoubleClick={() => selectComponent(data.id)}
      ref={ref}
      style={{ opacity }}
      className={`draggable`}
      onContextMenu={handleRightClick}
      sx={{ position: "relative" }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <DragIndicatorIcon style={{ marginRight: "10px", cursor: "move" }} />
        {component.props.label.EN}
        {component.props.required && (
          <span style={{ color: "red", marginLeft: "5px" }}>*</span>
        )}
        {component.props.invisible && (
          <VisibilityOffIcon style={{ marginLeft: "10px" }} />
        )}
        {component.props.disabled && (
          <PowerSettingsNewIcon style={{ marginLeft: "10px" }} />
        )}
      </div>
      <div>{renderedComponent}</div>
      {deleteBtnPosition && (
        <DeleteButton
          x={deleteBtnPosition.x}
          y={deleteBtnPosition.y}
          onDelete={handleDeleteClick}
        />
      )}
    </DraggablePaper>
  );
};

export default Component;
