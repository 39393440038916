import store from "./components/store";
import { msalInstance } from "./App";
import { loginActions } from "./components/store/loginSlice";

const authProvider = {
  login: async ({ email, password, loginMethod }) => {
    // const state = store.getState();
    // console.log(state, "THIS IS WHAT's BEING CALLED");
    if (loginMethod === "local") {
      const response = await fetch("https://nalipka.azurewebsites.net/azureauth/login", {
        method: "POST",
        body: JSON.stringify({ email, password }),
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      });

      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }

      const data = await response.json();
      store.dispatch(loginActions.setAccessToken(data.accessToken));
      return Promise.resolve();
    } else {
      try {
        const response = await msalInstance.loginPopup();

        // If login was successful, you'd have the response object which contains details about the logged-in user.
        // If you want to make additional logic/checks using the response, you can do it here.

        // Optionally: If you want to extract information from the response, you can do so. For example:
        // const user = response.account.username;
        // console.log(response);
        return Promise.resolve();
      } catch (error) {
        // If there's an error in the login process, this catch block will execute.
        // The error object will have details about what went wrong.
        console.error("Error during login:", error);

        // If you want to throw a custom error similar to the commented fetch logic:
        throw new Error(error.errorMessage || "Login failed");
      }
    }
  },
  logout: async () => {
    return fetch("https://nalipka.azurewebsites.net/azureauth/logout", {
      method: "POST",
      credentials: "include",
    }).then(() => {});
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: async () => {
    const token = store.getState().login.accessToken;
    // console.log(token);
    try {
      const response = await fetch(
        "https://nalipka.azurewebsites.net/azureauth/check-auth",
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Authorization-Local": `Bearer ${token}`,
          },
        }
      );

      if (response.status === 401) {
        throw new Error("Authentication failed");
      }

      if (response.status < 200 || response.status >= 300) {
        throw new Error("Network error");
      }

      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getPermissions: () => {
    return Promise.resolve();
  },
};

export default authProvider;
