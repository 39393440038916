import React, { useRef, useState } from "react";
import { useDrag } from "react-dnd";
import { COLUMN } from "../Constants";
import DropZone from "./DropZone";
import Component from "./Component";
import { Box } from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { DeleteButton } from "./DeleteButton";

const Column = ({
  data,
  handleDrop,
  path,
  components,
  selectComponent,
  handleDelete,
}) => {
  const [deleteBtnPosition, setDeleteBtnPosition] = useState(null);
  const handleRightClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // Hide menu if it's already open
    if (deleteBtnPosition) {
      setDeleteBtnPosition(null);
      return;
    }

    // Get bounding box of the parent component
    const boundingBox = ref.current.getBoundingClientRect();

    const x = e.clientX - boundingBox.left; // clientX and clientY are relative to the viewport, so adjust for element's position
    const y = e.clientY - boundingBox.top;

    setDeleteBtnPosition({ x, y });
  };

  const handleDeleteClick = () => {
    // Call your delete function here
    // After deleting, remove the delete button
    handleDelete(null, { path });
    setDeleteBtnPosition(null);
  };
  // console.log(
  //   "PRINTING OUT THE VALUE OF THE DATA CHILDREN before the crash",
  //   data.children,
  //   data
  // );
  const ref = useRef(null);

  const [{ isDragging }, drag] = useDrag({
    type: COLUMN,
    item: () => ({ id: data.id, children: data.children, path }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.5 : 1;
  drag(ref);

  const renderComponent = (component, currentPath) => {
    // console.log('this is how the data passed from column to component looks like:', component);
    return (
      <Component
        key={component.id}
        data={component}
        components={components}
        path={currentPath}
        selectComponent={selectComponent}
        handleDelete={handleDelete}
      />
    );
  };

  return (
    <Box
      ref={ref}
      sx={{
        opacity,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        width: "100%",
        border: 1,
        borderColor: "divider",
        p: 2,
        bgcolor: "background.paper",
        cursor: "grab",
        position: "relative"
      }}
      className="base draggable column"
      onContextMenu={handleRightClick}
    >
      {/* {data.id} */}
      <div style={{ display: "flex", alignItems: "center" }}>
        <DragIndicatorIcon style={{ marginBottom: "10px", cursor: "grab" }} />
      </div>
      {data.children.map((component, index) => {
        const currentPath = `${path}-${index}`;

        return (
          <React.Fragment key={component.id}>
            <DropZone
              data={{
                path: currentPath,
                childrenCount: data.children.length,
              }}
              onDrop={handleDrop}
            />
            {renderComponent(component, currentPath)}
          </React.Fragment>
        );
      })}
      <DropZone
        data={{
          path: `${path}-${data.children.length}`,
          childrenCount: data.children.length,
        }}
        onDrop={handleDrop}
        isLast
      />
      {deleteBtnPosition && (
        <DeleteButton
          x={deleteBtnPosition.x}
          y={deleteBtnPosition.y}
          onDelete={handleDeleteClick}
        />
      )}
    </Box>
  );
};
export default Column;