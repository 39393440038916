import React, { useRef } from "react";
import { Box } from "@mui/system";
import { Typography, Paper, Button } from "@mui/material";
import TextFieldEdit from "./editComponents/TextFieldEdit";
import FileUploadEdit from "./editComponents/FileUploadEdit";
import FileReferenceEdit from "./editComponents/FileReferenceEdit";
import DateEdit from "./editComponents/DateEdit";
import ParagraphEdit from "./editComponents/ParagraphEdit";
import IntegerEdit from "./editComponents/IntegerEdit";
import FloatEdit from "./editComponents/FloatEdit";
import SelectEdit from "./editComponents/SelectEdit";
import RadioGroupEdit from "./editComponents/RadioGroupEdit";
import CheckboxEdit from "./editComponents/CheckboxEdit";
import HtmlEdit from "./editComponents/HtmlEdit";
import TemplateReferenceEdit from "./editComponents/TemplateReferenceEdit";
import FileAttachmentEdit from "./editComponents/FileAttachmentEdit";

const FieldEditor = (props) => {
  const renderedEditorRef = useRef();

  const handleSave = () => {
    // Call a function on the currently rendered editor to get its current props
    const data = renderedEditorRef.current.getProps();
    // Call updateComponentProps with the new props
    // console.log("THIS IS THE DATA RETURNED FROM THE EIDTOR", data);
    props.updateComponentProps(data.componentId, data.localProps);
  };

  const component = props.components[props.selectedComponent];
  if (!component) {
    // If it doesn't exist, you can either render nothing or some placeholder
    return <Typography>No component selected</Typography>;
  }

  let renderedEditor;
  switch (component.type) {
    case "text-field":
      renderedEditor = (
        <TextFieldEdit
          key={component.id}
          ref={renderedEditorRef}
          // updateComponentProps={props.updateComponentProps}
          component={component}
        />
      );
      break;
    case "file-upload":
      renderedEditor = (
        <FileUploadEdit
          key={component.id}
          ref={renderedEditorRef}
          // updateComponentProps={props.updateComponentProps}
          component={component}
        />
      );
      break;
    case "file-reference":
      renderedEditor = (
        <FileReferenceEdit
          key={component.id}
          ref={renderedEditorRef}
          // updateComponentProps={props.updateComponentProps}
          component={component}
        />
      );
      break;
    case "date-field":
      renderedEditor = (
        <DateEdit
          key={component.id}
          ref={renderedEditorRef}
          // updateComponentProps={props.updateComponentProps}
          component={component}
        />
      );
      break;
    case "paragraph":
      renderedEditor = (
        <ParagraphEdit
          key={component.id}
          ref={renderedEditorRef}
          // updateComponentProps={props.updateComponentProps}
          component={component}
        />
      );
      break;
    case "integer":
      renderedEditor = (
        <IntegerEdit
          key={component.id}
          ref={renderedEditorRef}
          // updateComponentProps={props.updateComponentProps}
          component={component}
        />
      );
      break;
    case "float":
      renderedEditor = (
        <FloatEdit
          key={component.id}
          ref={renderedEditorRef}
          // updateComponentProps={props.updateComponentProps}
          component={component}
        />
      );
      break;
    case "select":
      renderedEditor = (
        <SelectEdit
          key={component.id}
          ref={renderedEditorRef}
          // updateComponentProps={props.updateComponentProps}
          component={component}
        />
      );
      break;
    case "radio-group":
      renderedEditor = (
        <RadioGroupEdit
          key={component.id}
          ref={renderedEditorRef}
          // updateComponentProps={props.updateComponentProps}
          component={component}
        />
      );
      break;
    case "checkbox":
      renderedEditor = (
        <CheckboxEdit
          key={component.id}
          ref={renderedEditorRef}
          // updateComponentProps={props.updateComponentProps}
          component={component}
        />
      );
      break;
    case "custom-html":
      renderedEditor = (
        <HtmlEdit
          key={component.id}
          ref={renderedEditorRef}
          // updateComponentProps={props.updateComponentProps}
          component={component}
        />
      );
      break;
    case "template-reference":
      renderedEditor = (
        <TemplateReferenceEdit
          key={component.id}
          ref={renderedEditorRef}
          // updateComponentProps={props.updateComponentProps}
          component={component}
        />
      );
      break;
    case "file-attachment":
      // console.log("WERE'GETTING HERE", component);
      renderedEditor = (
        <FileAttachmentEdit
          key={component.id}
          ref={renderedEditorRef}
          // updateComponentProps={props.updateComponentProps}
          component={component}
        />
      );
      break;

    default:
      renderedEditor = (
        <Box sx={{ width: "100%", overflow: "auto" }}>
          <Typography>The component type does not exist</Typography>
        </Box>
      );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        // justifyContent: "center",
        justifyContent: "flex-start",
        // alignItems: "center",
        // padding: 3,
        // paddingTop: 30, // matches the height of your navbar
        position: "fixed",
        height: "100vh",
        // width: "100%",
        overflowY: "auto",
      }}
    >
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: 3,
          paddingBottom: "4rem", // adjust this value as needed
          width: "100%",
          height: "100%",
        }}
        elevation={3}
      >
        <Typography variant="h6" gutterBottom>
          Field Editor
        </Typography>
        {renderedEditor}
        {/* <TextFieldEdit
          updateComponentProps={props.updateComponentProps}
          component={component}
        /> */}
        {/* The rest of your field editing components go here */}
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
      </Paper>
    </Box>
  );
};

export default FieldEditor;
