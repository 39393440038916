import {
  TextField,
  Switch,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { cloneDeep } from "lodash";
import { useState, forwardRef, useImperativeHandle } from "react";
import CopyToClipboardButton from "../helperComponents/CopyToClipboardButton";

const HtmlEdit = forwardRef(({ component }, ref) => {
  const [localProps, setLocalProps] = useState(component.props);

  useImperativeHandle(ref, () => ({
    getProps: () => ({ localProps, componentId: component.id }),
  }));

  const handleChange = (type, value) => {
    const newProps = cloneDeep(localProps);
    if (type.includes(".")) {
      const splitType = type.split(".");
      newProps.label[splitType[1]] = value;
      setLocalProps(newProps);
      return;
    }
    switch (type) {
      case "XPATH":
        newProps.XPATH = value;
        break;
      case "disabled":
        newProps.disabled = value;
        break;
      case "required":
        newProps.required = value;
        break;
      case "invisible":
        newProps.invisible = value;
        break;
      case "value":
        newProps.value = value;
        break;
      default:
        return;
    }
    setLocalProps(newProps);
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64returnvalue = reader.result;
      const imgTag = `<img src="${base64returnvalue}"/>`;
      handleChange("value", imgTag);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <div style={{ width: "100%", overflow: "auto" }}>
      <TextField
        sx={{ borderRadius: 0 }}
        label="XPATH"
        value={localProps.XPATH}
        onChange={(e) => handleChange("XPATH", e.target.value)}
        fullWidth
      />
      <CopyToClipboardButton textToCopy={component.id} />
      <FormControlLabel
        control={
          <Switch
            checked={localProps.disabled}
            onChange={(e) => handleChange("disabled", e.target.checked)}
          />
        }
        label="Disabled"
      />
      <FormControlLabel
        control={
          <Switch
            checked={localProps.required}
            onChange={(e) => handleChange("required", e.target.checked)}
          />
        }
        label="Required"
      />
      <FormControlLabel
        control={
          <Switch
            checked={localProps.invisible}
            onChange={(e) => handleChange("invisible", e.target.checked)}
          />
        }
        label="Invisible"
      />
      <TextField
        sx={{ borderRadius: 0 }}
        label="Value"
        value={localProps.value}
        onChange={(e) => handleChange("value", e.target.value)}
        fullWidth
        inputProps={{ maxLength: localProps.maxLength }}
        multiline
        rows={4}
      />
      <Button sx={{mb: "10px"}} variant="contained" component="label">
        Add Image
        <input
          type="file"
          accept="image/*"
          hidden
          onChange={handleFileSelect}
        />
      </Button>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            "&.Mui-expanded": {
              minHeight: "initial",
            },
            "&.Mui-expanded .MuiAccordionSummary-content": {
              margin: 0,
            },
          }}
        >
          <Typography>Languages</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: "column" }}>
          {Object.entries(localProps.label).map(([lang, text]) => (
            <TextField
              sx={{ borderRadius: 0 }}
              key={lang}
              label={`Label (${lang})`}
              value={text}
              onChange={(e) => handleChange(`label.${lang}`, e.target.value)}
              fullWidth
            />
          ))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
});

export default HtmlEdit;
