import React, { useCallback, useState, useEffect } from "react";
import { DndProvider } from "react-dnd";
// import withScrolling from "react-dnd-scrollzone";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Box } from "@mui/material";
import FieldEditor from "./FieldEditor";
// import DropZone from "./essentials/DropZone";
import { SIDEBAR_ITEM, COLUMN, COMPONENT, DEFAULT_PROPS } from "./Constants";
import ComponentPalette from "./ComponentPalette";
import FormBuilderArea from "./FormBuilderArea";
import initialData from "./initial-data";
import {
  handleMoveWithinParent,
  handleMoveToDifferentParent,
  handleMoveSidebarComponentIntoParent,
  handleRemoveItemFromLayout,
  findComponentIdByPath,
  findAllNestedComponentIds,
} from "./helpers";
// import TestComponent from "./essentials/TestComponent";
import { scrollWindow } from "./helpers";
import { validateResponse } from "./validateJSON";
import { Edit, useEditContext } from "react-admin";
import { v4 as uuidv4 } from "uuid";
import { useDataProvider } from "react-admin";
import { useLocation } from "react-router-dom";
// import { cloneDeep } from "lodash";

const InnerFormBuilder = () => {
  const dataProvider = useDataProvider();
  const { record, isLoading } = useEditContext();
  const initialLayout = initialData.layout;
  const initialComponents = initialData.components;
  const initialRules = initialData.rules;
  const initialActions = initialData.actions;
  const [layout, setLayout] = useState(initialLayout);
  const [components, setComponents] = useState(initialComponents);
  const [fieldEditorOpen, setFieldEditorOpen] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState("");
  const [rules, setRules] = useState(initialRules); // existing rules
  const [actions, setActions] = useState(initialActions);
  const [imageSrc, setImageSrc] = useState(record?.thumbnailUrl || null); // Initialize state with props.imageSrc or null
  const [template_Name, setTemplateName] = useState(record.template_Name);
  const [isTemplateVisible, setIsTemplateVisible] = useState(
    record?.isPublic || false
  );
  const [isReferenceable, setIsReferenceable] = useState(
    record?.isReferenceable || false
  );
  const [template_Description, setTemplateDescription] = useState(
    record?.template_Description || null
  );
  const [imageFile, setImageFile] = useState(null);
  const [imageCleared, setImageCleared] = useState(false);
  const location = useLocation();
  useEffect(() => {
    const handleDragOver = (event) => {
      event.preventDefault();
      scrollWindow(event);
    };

    document.addEventListener("dragover", handleDragOver);

    return () => {
      document.removeEventListener("dragover", handleDragOver);
    };
  }, []);

  useEffect(() => {
    // Update template name and description
    // console.log(record, "ZZZZZZZZZZZZZZ");
    setTemplateName(record.template_Name);
    setTemplateDescription(record.template_Description);
    setIsTemplateVisible(record?.isPublic || false);
    setIsReferenceable(record?.isReferenceable || false);
    setImageSrc(record.thumbnailUrl);

    // Process the template JSON
    if (record && record.template_JSON) {
      const parsedJSON = JSON.parse(record.template_JSON);
      if (validateResponse(parsedJSON)) {
        setLayout(parsedJSON.layout);
        setComponents(parsedJSON.components);
        setRules(parsedJSON.rules);
        setActions(parsedJSON.actions);
      }
    }
  }, [record]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Check if there are unsaved changes
      const hasUnsavedChanges = true; // Set this based on your logic
      if (hasUnsavedChanges) {
        event.preventDefault();
        event.returnValue = ''; // Modern browsers require this for showing a confirmation dialog
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // const updateComponentProps = (component) => {
  //   setComponents((state) => {
  //     console.log(state, "AAAAAAAAAAAAAAAAAAAAAAAAAAAAAA");
  //     return state;
  //   });
  //   setComponents((state) => ({ ...state, [component.id]: component }));
  // };

  const addRule = (newRule) => {
    setRules((state) => {
      const newRules = [...state, newRule];
      return newRules;
    });
  };
  const addAction = (newAction) => {
    setActions((state) => {
      const newActions = [...state, newAction];
      return newActions;
    });
  };

  const updateComponentProps = (componentID, props) => {
    setComponents((state) => {
      const newState = { ...state };
      newState[componentID].props = props;
      return newState;
    });
  };

  const toggleFieldEditorOpen = () => {
    setFieldEditorOpen((prev) => !prev);
  };

  const setIdOfSelectedComponent = (componentId) => {
    setSelectedComponent(componentId);
  };

  const selectComponent = (componentId) => {
    if (componentId !== selectedComponent) {
      setSelectedComponent(componentId);
      setFieldEditorOpen(true);
    } else if (fieldEditorOpen) {
      setFieldEditorOpen(false);
    } else {
      setFieldEditorOpen(true);
    }
  };

  const sendDataToServer = async () => {
    const resourceType = location.pathname.split("/")[1];
    const formData = new FormData();
    // formData.append("layout", JSON.stringify(layout));
    // formData.append("components", JSON.stringify(components));
    // formData.append("rules", JSON.stringify(rules));
    if (template_Name !== undefined && template_Name !== null) {
      formData.append("template_Name", template_Name);
    }

    if (isTemplateVisible !== undefined && isTemplateVisible !== null) {
      formData.append("isPublic", isTemplateVisible);
    }
    if (isReferenceable !== undefined && isReferenceable !== null) {
      formData.append("isReferenceable", isReferenceable);
    }

    if (template_Description !== undefined && template_Description !== null) {
      formData.append("template_Description", template_Description);
    }

    if (layout && components && rules && actions) {
      formData.append(
        "template_JSON",
        JSON.stringify({ layout, components, rules, actions })
      );
    }

    if (imageFile) {
      formData.append("thumbnail", imageFile); // Append the actual file object
    }
    formData.append("delete_avatar", imageCleared);
    // console.log(formData);
    try {
      // Create or update the record using your custom data provider
      const recordToUpdate = { id: record.id, data: formData }; // Assuming you are updating an existing record
      const updatedRecord = await dataProvider.update(
        resourceType,
        recordToUpdate
      );
      // console.log("Data sent successfully:", updatedRecord);
    } catch (error) {
      // Handle errors if the update fails
      console.error("Failed to send data to the server:", error);
    }
    // Now you can use fetch or axios to send formData to the server.
  };

  const handleDropToTrashBin = useCallback(
    (dropZone, item) => {
      const splitItemPath = item.path.split("-");
      const componentIDFound = findComponentIdByPath(layout, splitItemPath);

      if (selectedComponent === componentIDFound) {
        setSelectedComponent(""); // Unselect the component
        setFieldEditorOpen(false);
      }

      // Find all nested component IDs
      const nestedComponentIds = findAllNestedComponentIds(
        layout,
        splitItemPath
      );
      // console.log(
      //   nestedComponentIds,
      //   "############################################"
      // );

      // Filter out the component and its nested components
      const newComponents = Object.entries(components).reduce(
        (acc, [key, value]) => {
          if (key !== componentIDFound && !nestedComponentIds.includes(key)) {
            acc[key] = value;
          }
          return acc;
        },
        {}
      );

      setComponents(newComponents);
      setLayout(handleRemoveItemFromLayout(layout, splitItemPath));
    },
    [layout, components, selectedComponent]
  );

  const handleDrop = useCallback(
    (dropZone, item) => {
      // console.log("dropZone", dropZone);
      // console.log("item", item);

      const splitDropZonePath = dropZone.path.split("-");
      const pathToDropZone = splitDropZonePath.slice(0, -1).join("-");

      // console.log(
      //   "JJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJ",
      //   item
      // );
      const newItem = { id: item.id, type: item.type };
      if (item.type === COLUMN) {
        newItem.children = item.children;
      }

      // sidebar into
      if (item.type === SIDEBAR_ITEM) {
        // 1. Move sidebar item into page
        const newComponent = {
          id: "a" + uuidv4(),
          type: item.componentType,
          props: DEFAULT_PROPS[item.componentType],
        };
        const newItem = {
          id: newComponent.id,
          type: COMPONENT,
        };
        // console.log("THIS IS NEWCOMPONENT AND NEWITEM ", newComponent, newItem);
        setComponents((state) => ({
          ...state,
          [newComponent.id]: newComponent,
        }));
        setLayout((prevState) => {
          return handleMoveSidebarComponentIntoParent(
            prevState,
            splitDropZonePath,
            newItem
          );
        });
        return;
      }

      // move down here since sidebar items dont have path
      const splitItemPath = item.path.split("-");
      const pathToItem = splitItemPath.slice(0, -1).join("-");

      // 2. Pure move (no create)
      if (splitItemPath.length === splitDropZonePath.length) {
        // 2.a. move within parent
        if (pathToItem === pathToDropZone) {
          setLayout(
            handleMoveWithinParent(layout, splitDropZonePath, splitItemPath)
          );
          return;
        }

        // 2.b. OR move different parent
        // TODO FIX columns. item includes children
        setLayout(
          handleMoveToDifferentParent(
            layout,
            splitDropZonePath,
            splitItemPath,
            newItem
          )
        );
        return;
      }

      // 3. Move + Create
      setLayout(
        handleMoveToDifferentParent(
          layout,
          splitDropZonePath,
          splitItemPath,
          newItem
        )
      );
    },
    [layout, components]
  );

  return (
    <Box
      display="grid"
      gridTemplateColumns={fieldEditorOpen ? "1fr 3fr 1fr" : "1fr 4fr"}
      width="100%"
      // height="100vh"
    >
      <DndProvider backend={HTML5Backend}>
        {/* <TestComponent /> */}
        <Box sx={{ overflow: "auto" }}>
          <ComponentPalette
            template_Description={template_Description}
            setTemplateDescription={setTemplateDescription}
            template_Name={template_Name}
            setTemplateName={setTemplateName}
            imageSrc={imageSrc}
            setImageSrc={setImageSrc}
            setTemplateVisibility={setIsTemplateVisible}
            isTemplateVisible={isTemplateVisible}
            setIsReferenceable={setIsReferenceable}
            isReferenceable={isReferenceable}
            components={components}
            addRule={addRule}
            addAction={addAction}
            setRules={setRules}
            setActions={setActions}
            rules={rules}
            actions={actions}
            layout={layout}
            handleSave={sendDataToServer}
            setImageFile={setImageFile}
            setImageCleared={setImageCleared}
          />
        </Box>
        <Box>
          <FormBuilderArea
            components={components}
            layout={layout}
            handleDrop={handleDrop}
            handleDelete={handleDropToTrashBin}
            // setIdOfSelectedComponent={setIdOfSelectedComponent}
            // toggleFieldEditor={toggleFieldEditorOpen}
            selectComponent={selectComponent}
          />
        </Box>
        {fieldEditorOpen && (
          <Box>
            <FieldEditor
              toggleFieldEditor={toggleFieldEditorOpen}
              setIsOpen={setFieldEditorOpen}
              components={components}
              selectedComponent={selectedComponent}
              updateComponentProps={updateComponentProps}
              setSelectedComponent={setSelectedComponent}
            />
          </Box>
        )}
      </DndProvider>
    </Box>
  );
};

const FormBuilder = () => {
  return (
    <Edit>
      <InnerFormBuilder />
    </Edit>
  );
};

export default FormBuilder;
