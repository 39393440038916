import React, { useEffect, useState } from "react";
import { Admin, Resource, CustomRoutes } from "react-admin";
import { Route } from "react-router-dom";
// import restProvider from "ra-data-simple-rest";
import authProvider from "./authProvider";
import azureAuthProvider from "./azureAuthProvider";
import azureDataProvider from "./azureDataProvider";
import CompanyEdit from "./components/ListPages/Company";
import AdminEdit from "./components/editPages/AdminEdit";
import CompanyUserEdit from "./components/editPages/CompanyUserEdit";
// import CompanyList from "./components/ListPages/CompanyList";
import CompanyRoleUsersList from "./components/ListPages/CompanyRoleUsersList";
// import RefreshTokenList from "./components/ListPages/RefreshTokenList";
// import RoleList from "./components/ListPages/RoleList";
// import TaskList from "./components/ListPages/TaskList";
import UserList from "./components/ListPages/UserList";
import TaskTemplateList from "./components/ListPages/TaskTemplateLIst";
import RequestTemplateList from "./components/ListPages/ReqeustTemplateList";
// import UserRequestList from "./components/ListPages/UserRequestList";
// import UserRoleCompanyList from "./components/ListPages/UserRoleCompanyList";
import AdminList from "./components/ListPages/AdminList";
import dataProvider from "./dataProvider";
// import UserEdit from "./components/editPages/UserEdit";
// import CompanyEdit from "./components/editPages/CompanyEdit";
// import CompanyRoleUsersEdit from "./components/editPages/CompanyRoleUsersEdit";
// import RefreshTokenEdit from "./components/editPages/RefreshTokenEdit";
// import RoleEdit from "./components/editPages/RoleEdit";
// import TaskEdit from "./components/editPages/TaskEdit";
// import UserRequestEdit from "./components/editPages/UserRequestEdit";
// import CompanyCreate from "./components/createPages/CompanyCreate";
// import RefreshTokenCreate from "./components/createPages/RefreshTokenCreate";
// import RoleCreate from "./components/createPages/RoleCreate";
// import TaskCreate from "./components/createPages/TaskCreate";
// import UserCreate from "./components/createPages/UserCreate";
import TaskTemplateCreate from "./components/createPages/TaskTemplate.create";
import CompanyAdminCreate from "./components/createPages/CompanyAdminCreate";
import RequestTemplateCreate from "./components/createPages/RequestTemplateCreate";
// import UserRequestCreate from "./components/createPages/UserRequestCreate";
// import CompanyRoleUsersCreate from "./components/createPages/CompanyRoleUsersCreate";
// import UserRoleCompanyCreate from "./components/createPages/UserRoleCompanyCreate";
// import BusinessIcon from "@mui/icons-material/Business";
// import BadgeIcon from "@mui/icons-material/Badge";
// import RefreshIcon from "@mui/icons-material/Refresh";
// import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
// import TaskIcon from "@mui/icons-material/Task";
import GroupIcon from "@mui/icons-material/Group";
import NewspaperIcon from "@mui/icons-material/Newspaper";
// import RequestPageIcon from "@mui/icons-material/RequestPage";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import BusinessIcon from "@mui/icons-material/Business";
// import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
// import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import FormBuilder from "./components/formBuilder/FormBuilder";
// import FormBuilderPage from "./components/FormBuilderPage";
import { MyLayout } from "./components/MyLayout";
import MyLoginPage from "./components/MyLoginPage";
// import store from "./components/store";
import MyTheme from "./MyTheme";
import { Box } from "@mui/system";

//msal configuration
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./components/authConfig";
import { useSelector } from "react-redux";
import { loginRequest } from "./components/authConfig";
import { fetchUtils } from "ra-core";
import FormPreviewer from "./components/formPreviewer/FormPreviewer";
// import dataProvider from "./dataProvider";
// import azureDataProvider from "./azureDataProvider";

export const msalInstance = new PublicClientApplication(msalConfig);

function App() {
  const [currentAuthProvider, setCurrentAuthProvider] = useState(authProvider);
  const [currentDataProvider, setCurrentDataProvider] = useState({});
  const accessToken = useSelector((state) => state.login.accessToken);
  const loginMethod = useSelector((state) => state.login.loginMethod);
  // const [someparam, setSomeParam] = useState(0);
  // setTimeout(
  //   setSomeParam((state) => state + 1),
  //   1000
  // );
  // console.log(someparam);

  useEffect(() => {
    let dataProviderInstance;
    if (loginMethod === "local") {
      dataProviderInstance = dataProvider();
    } else {
      dataProviderInstance = azureDataProvider();
    }
    setCurrentDataProvider(dataProviderInstance);
    // const HttpClient = async (url, options = {}) => {
    //   if (!options.headers) {
    //     options.headers = new Headers({ Accept: "application/json" });
    //   }
    //   if (loginMethod === "local") {
    //     const token = store.getState((state) => state.login.accessToken);
    //     options.headers.set("Authorization-Local", `Bearer ${token}`);
    //   } else {
    //     const response = await msalInstance.acquireTokenSilent({
    //       scopes: loginRequest.scopes,
    //       account: msalInstance.getAllAccounts()[0],
    //     });
    //     const token = response.accessToken;
    //     options.headers.set("Authorization-Azure", `Bearer ${token}`);
    //   }
    //   options.credentials = "include";
    //   return fetchUtils.fetchJson(url, options);
    // };
    // setHttpClient(HttpClient);
  }, [accessToken, loginMethod]);

  const setAzureAuth = () => {
    setCurrentAuthProvider(azureAuthProvider);
  };

  const setLocalAuth = () => {
    setCurrentAuthProvider(authProvider);
  };

  const setAzureData = () => {
    // setCurrentDataProvider(azureDataProvider);
  };

  const setLocalData = () => {
    // setCurrentDataProvider(dataProvider);
  };

  // console.log("the current mode is:", currentDataProvider);

  return (
    <MsalProvider instance={msalInstance}>
      <Admin
        // theme={MyTheme}
        loginPage={
          <MyLoginPage
            setAzureAuth={setAzureAuth}
            setLocalAuth={setLocalAuth}
            setAzureData={setAzureData}
            setLocalData={setLocalData}
          />
        }
        dataProvider={currentDataProvider}
        authProvider={currentAuthProvider}
        layout={MyLayout}
      >
        {/* <Resource
        name="companies"
        list={CompanyList}
        edit={CompanyEdit}
        create={CompanyCreate}
        icon={BusinessIcon}
      /> */}
        {/* <Resource
          name="roles"
          list={RoleList}
          edit={RoleEdit}
          create={RoleCreate}
          icon={BadgeIcon}
        /> */}
        <Resource
          options={{ label: "Task templates" }}
          name="task-templates"
          list={TaskTemplateList}
          edit={<FormBuilder />}
          create={TaskTemplateCreate}
          icon={NewspaperIcon}
        />
        <Resource
          options={{ label: "Request templates" }}
          name="request-templates"
          list={RequestTemplateList}
          edit={<FormBuilder />}
          create={RequestTemplateCreate}
          icon={NewspaperIcon}
        />
        <Resource
          options={{ label: "Company admins" }}
          name="company-admins"
          list={AdminList}
          edit={AdminEdit}
          create={CompanyAdminCreate}
          icon={AdminPanelSettingsIcon}
        />
        <Resource
          options={{ label: "Users" }}
          name="users"
          list={UserList}
          edit={CompanyUserEdit}
          // create={UserCreate}
          icon={GroupIcon}
        />
        {/* <Resource
          name="user-role-company"
          list={UserRoleCompanyList}
          // edit={UserEdit}
          create={UserRoleCompanyCreate}
          icon={AssignmentIndIcon}
        /> */}
        {/* <Resource
          name="user-requests"
          list={UserRequestList}
          edit={UserRequestEdit}
          create={UserRequestCreate}
          icon={RequestPageIcon}
        /> */}
        <Resource
          options={{ label: "Company" }}
          name="company"
          icon={BusinessIcon}
          list={CompanyEdit}
        />
        <CustomRoutes>
          <Route path="/preview" element={<Box sx={{padding: 2}}><FormPreviewer /></Box>} />
        </CustomRoutes>
      </Admin>
    </MsalProvider>
  );
}

export default App;
