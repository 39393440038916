import React, { useState, useEffect } from "react";
import {
  Typography,
  List,
  ListItem,
  IconButton,
  ListItemText,
  Box,
  Input,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useDrag } from "react-dnd";
import { useMatch } from "react-router-dom";
// import { useSelector } from "react-redux";
import TextFieldIcon from "@mui/icons-material/TextFields";
// import ImageIcon from "@mui/icons-material/Image";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import AddLinkIcon from "@mui/icons-material/AddLink";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SegmentIcon from "@mui/icons-material/Segment";
import NumbersIcon from "@mui/icons-material/Numbers";
import Looks3Icon from "@mui/icons-material/Looks3";
import EventIcon from "@mui/icons-material/Event";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CodeIcon from "@mui/icons-material/Code";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
// import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import { SIDEBAR_ITEMS } from "./Constants";
import OverlayComponent from "./helperComponents/OverlayComponent";
import RuleEditor from "./helperComponents/RuleEditor";
import { cloneDeep } from "lodash";
import { v4 as uuid } from "uuid";
import JavascriptAction from "./helperComponents/JavascriptAction";
import ObjectPreview from "./helperComponents/ObjectPreview";

const renderIcon = (iconName) => {
  switch (iconName) {
    case "TextFieldIcon":
      return <TextFieldIcon />;
    // case "ImageIcon":
    //   return <ImageIcon />;
    case "FileUploadIcon":
      return <FileUploadIcon />;
    case "AddLinkIcon":
      return <AddLinkIcon />;
    case "EventIcon":
      return <EventIcon />;
    case "RadioButtonCheckedIcon":
      return <RadioButtonCheckedIcon />;
    case "CheckBoxIcon":
      return <CheckBoxIcon />;
    case "ArrowDropDownIcon":
      return <ArrowDropDownIcon />;
    case "SegmentIcon":
      return <SegmentIcon />;
    case "Looks3Icon":
      return <Looks3Icon />;
    case "NumbersIcon":
      return <NumbersIcon />;
    case "CodeIcon":
      return <CodeIcon />;
    case "IntegrationInstructionsIcon":
      return <IntegrationInstructionsIcon />;
    case "AttachFileIcon":
      return <AttachFileIcon />;
    // case "ViewColumnIcon":
    //   return <ViewColumnIcon />;
    default:
      return null;
  }
};

const DraggableComponent = ({
  id,
  name,
  icon,
  index,
  dragType,
  componentType,
}) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: dragType,
    item: { id, name, index, componentType, type: dragType },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <Box
      ref={drag}
      sx={{
        display: "flex",
        alignItems: "center",
        padding: 1,
        marginBottom: 1,
        border: "1px solid",
        borderColor: "divider",
        cursor: "grab",
        // borderRadius: 1,
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      {renderIcon(icon)}
      <Typography variant="body1" marginLeft={1}>
        {name}
      </Typography>
    </Box>
  );
};

const ComponentPalette = (props) => {
  // const [imageSrc, setImageSrc] = useState(props.imageSrc || null); // Initialize state with props.imageSrc or null
  // const [template_Name, setTemplateName] = useState(props.template_Name);
  // const [template_Description, setTemplateDescription] = useState(
  //   props.template_Description
  // );
  const match = useMatch("/:type/:id");
  const [showRuleBuilder, setShowRuleBuilder] = useState(false);
  const [showActionBuilder, setShowActionBuilder] = useState(false);
  const [showJsonStructure, setShowJsonStructure] = useState(false);

  const [activeRule, setActiveRule] = useState({
    ruleName: "",
    cellBehavior: "",
    elseBehavior: "",
    affectedComponent: "",
    rows: [
      {
        type: "attribute",
        componentId: "",
        key: "",
        value: "",
        operator: "IF",
        attributeSign: "",
      },
    ],
  });

  const [activeAction, setActiveAction] = useState({
    ruleName: "",
    affectedComponent: "",
    actionJS: "",
    actionBehavior: {
      "on-load": false,
      "on-update": false,
      "on-save": false,
    },
  });

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // For preview
      const reader = new FileReader();
      reader.onloadend = () => {
        props.setImageSrc(reader.result);
        props.setImageCleared(false);
      };
      reader.readAsDataURL(file);

      // Store the actual file object for sending to the server
      props.setImageFile(file);
    }
  };

  const handleActiveRuleUpdate = (type, value, rowIndex = null) => {
    const newActiveRule = cloneDeep(activeRule);
    switch (type) {
      case "ruleName":
        newActiveRule.ruleName = value;
        break;
      case "cellBehavior":
        newActiveRule.cellBehavior = value;
        break;
      case "elseBehavior":
        newActiveRule.elseBehavior = value;
        break;
      case "affectedComponent":
        newActiveRule.affectedComponent = value;
        break;
      case "rows":
        if (rowIndex !== null) {
          // Update a specific row
          newActiveRule.rows[rowIndex] = {
            ...newActiveRule.rows[rowIndex],
            ...value,
          };
        } else {
          // Replace the entire rows array
          newActiveRule.rows = value;
        }
        break;
      default:
        break;
    }
    setActiveRule(newActiveRule);
  };

  const handleActiveActionUpdate = (type, value) => {
    const newActiveAction = cloneDeep(activeAction);
    switch (type) {
      case "actionName":
        newActiveAction.actionName = value;
        break;
      case "affectedComponent":
        newActiveAction.affectedComponent = value;
        break;
      case "actionJS":
        newActiveAction.actionJS = value;
        break;
      case "actionBehavior":
        newActiveAction.actionBehavior[value.key] = value.value;
        break;
      default:
        break;
    }
    setActiveAction(newActiveAction);
  };

  const createNewRuleHanlder = () => {
    const newRuleName = `New rule #${props.rules.length + 1}`;
    const newRule = {
      uuid: uuid(),
      ruleName: newRuleName,
      cellBehavior: "",
      elseBehavior: "",
      affectedComponent: "",
      rows: [
        {
          type: "attribute",
          componentId: "",
          key: "",
          value: "",
          operator: "IF",
          attributeSign: "",
        },
      ],
    };
    props.addRule(newRule);
  };

  const createNewActionHanlder = () => {
    const newActionName = `New action #${props.actions.length + 1}`;
    const newAction = {
      uuid: uuid(),
      actionName: newActionName,
      affectedComponent: "",
      actionJS: "",
      actionBehavior: {
        "on-load": false,
        "on-update": false,
        "on-save": false,
      },
    };
    props.addAction(newAction);
  };

  const CloseHandler = () => {
    setActiveRule(null);
    setShowRuleBuilder(false);
  };

  const CloseHandlerAction = () => {
    setActiveAction(null);
    setShowActionBuilder(false);
  };

  const SaveHandler = () => {
    const newActiveRule = cloneDeep(activeRule);
    props.setRules((state) => {
      return state.map((element) => {
        if (element.uuid === activeRule.uuid) {
          return newActiveRule;
        }
        return element;
      });
    });
    CloseHandler();
  };
  const SaveActionHandler = () => {
    const newActiveAction = cloneDeep(activeAction);
    props.setActions((state) => {
      return state.map((element) => {
        if (element.uuid === activeAction.uuid) {
          return newActiveAction;
        }
        return element;
      });
    });
    CloseHandlerAction();
  };
  const editRule = (ruleUUID) => {
    // Implement your edit logic here.
    // For example: Load the rule into activeRule and show the editor
    let newActiveRule = null;
    newActiveRule = props.rules.find((rule) => rule.uuid === ruleUUID);
    // const newActiveRule = props.rules[ruleIndex];
    setActiveRule(newActiveRule);
    if (newActiveRule !== null) {
      setShowRuleBuilder(true);
    }
  };

  const deleteRule = (ruleUUID) => {
    const sameRule = activeRule && ruleUUID === activeRule.uuid;
    props.setRules((state) => {
      return state.filter((element) => {
        return element.uuid !== ruleUUID;
      });
    });
    if (sameRule) {
      CloseHandler();
    }
  };

  const editAction = (actionUUID) => {
    // Implement your edit logic here.
    // For example: Load the rule into activeRule and show the editor
    let newActiveAction = null;
    newActiveAction = props.actions.find(
      (action) => action.uuid === actionUUID
    );
    // const newActiveRule = props.rules[ruleIndex];
    setActiveAction(newActiveAction);
    if (newActiveAction !== null) {
      setShowActionBuilder(true);
    }
  };

  const deleteAction = (actionUUID) => {
    const sameAction = activeAction && actionUUID === activeAction.uuid;
    props.setActions((state) => {
      return state.filter((element) => {
        return element.uuid !== actionUUID;
      });
    });
    if (sameAction) {
      CloseHandlerAction();
    }
  };

  const handlePreview = (templateData) => {
    // const uniqueKey = `preview_${Date.now()}`;
    // localStorage.setItem(
    //   uniqueKey,
    //   JSON.stringify({ ...templateData, referenceCount: 3 })
    // );
    // const path = `${window.location.origin}/#/preview?type=${match.params.type}&key=${uniqueKey}`;
    const path = `${window.location.origin}/#/preview?type=${match.params.type}&templateId=${match.params.id}`;
    window.open(path);
  };

  // const formComponents = useSelector((state) => state.palette);
  return (
    <Box
      sx={{
        // height: "100vh",
        // position: "sticky",
        top: 0,
        overflowY: "auto",
        padding: 2,
        // paddingRight: 2, // Added padding to the right to separate it from FormBuilderArea
      }}
    >
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Available Components</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: "column" }}>
          {SIDEBAR_ITEMS.map((sidebarItem, index) => (
            <DraggableComponent
              key={sidebarItem.id}
              id={sidebarItem.id}
              name={sidebarItem.component.content}
              icon={sidebarItem.component.icon}
              index={index}
              dragType={sidebarItem.type}
              componentType={sidebarItem.component.type}
            />
          ))}
        </AccordionDetails>
      </Accordion>
      {/* <Typography
        sx={{
          pt: "20px",
          pb: "20px",
          display: "flex",
          justifyContent: "center",
        }}
        variant="h6"
      >
        Available Components:
      </Typography> */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Edit Template Data</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: "column" }}>
          <Box>
            {/* Text fields for Template Name and Description */}
            {match.params.type === "request-templates" && (
              <FormControlLabel
                control={
                  <Switch
                    checked={props.isTemplateVisible}
                    onChange={(e) =>
                      props.setTemplateVisibility(!!e.target.checked)
                    }
                    name="templateVisibility"
                    color="primary"
                  />
                }
                label="Template Visibility"
                sx={{
                  marginTop: 2, // Adjust margin to align with the TextField style
                  marginBottom: 2,
                  width: "100%",
                }}
              />
            )}
            <FormControlLabel
              control={
                <Switch
                  checked={props.isReferenceable}
                  onChange={(e) => props.setIsReferenceable(!!e.target.checked)}
                  name="isReferenceable"
                  color="primary"
                />
              }
              label="Template Referenceability"
              sx={{
                marginBottom: 2,
                width: "100%",
              }}
            />
            <TextField
              label="Template Name"
              variant="outlined"
              fullWidth
              margin="normal"
              value={props.template_Name}
              onChange={(e) => props.setTemplateName(e.target.value)}
            />
            <TextField
              label="Template Description"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              margin="normal"
              value={props.template_Description}
              onChange={(e) => props.setTemplateDescription(e.target.value)}
            />
            {/* File Input and Image Preview */}
            <Box
              sx={{ display: "flex", alignItems: "center", margin: "16px 0" }}
            >
              <Typography variant="body1" sx={{ marginRight: 2 }}>
                Upload Image:
              </Typography>
              <Input
                type="file"
                accept="image/*"
                onChange={handleImageChange} // Add this line to trigger image update
              />
              {/* Conditionally render image */}
              {props.imageSrc ? (
                <img
                  src={props.imageSrc}
                  alt="Uploaded preview"
                  style={{
                    marginLeft: "16px",
                    width: "50px",
                    height: "auto",
                  }}
                />
              ) : null}
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      {showRuleBuilder && (
        <OverlayComponent
          onCancel={CloseHandler}
          onClose={CloseHandler}
          onOk={SaveHandler}
          title="Edit Rule"
        >
          <RuleEditor
            activeRule={activeRule}
            handleActiveRuleUpdate={handleActiveRuleUpdate}
            components={props.components}
          />
        </OverlayComponent>
      )}
      {showActionBuilder && (
        <OverlayComponent
          onCancel={CloseHandlerAction}
          onClose={CloseHandlerAction}
          onOk={SaveActionHandler}
          title="Edit Action"
        >
          <JavascriptAction
            activeAction={activeAction}
            handleActiveActionUpdate={handleActiveActionUpdate}
            components={props.components}
          />
        </OverlayComponent>
      )}
      {showJsonStructure && (
        <OverlayComponent
          onCancel={() => {
            setShowJsonStructure(false);
          }}
          onClose={() => {
            setShowJsonStructure(false);
          }}
          onOk={() => {
            setShowJsonStructure(false);
          }}
          title="Preview"
        >
          <ObjectPreview json={props.components} />
        </OverlayComponent>
      )}

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Rules</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: "column" }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            style={{ marginBottom: "10px" }}
            onClick={createNewRuleHanlder}
          >
            New Rule
          </Button>
          {/* <Button
            variant="contained"
            onClick={() => {
              setShowRuleBuilder((state) => !state);
            }}
          >
            Toggle rules
          </Button> */}
          {props.rules.length > 0 ? (
            <List>
              {props.rules.map((rule, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={rule.ruleName}
                    sx={{
                      maxWidth: "calc(100% - 100px)", // Adjust as needed
                      whiteSpace: "nowrap",
                      overflow: "auto",
                      textOverflow: "ellipsis",
                    }}
                  />
                  <IconButton onClick={() => editRule(rule.uuid)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => deleteRule(rule.uuid)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography>There are no rules applied to this form</Typography>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Actions</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: "column" }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            style={{ marginBottom: "10px" }}
            onClick={createNewActionHanlder}
          >
            New Action
          </Button>
          {/* <Button
            variant="contained"
            onClick={() => {
              setShowRuleBuilder((state) => !state);
            }}
          >
            Toggle rules
          </Button> */}
          {props.actions.length > 0 ? (
            <List>
              {props.actions.map((action, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={action.actionName}
                    sx={{
                      maxWidth: "calc(100% - 100px)", // Adjust as needed
                      whiteSpace: "nowrap",
                      overflow: "auto",
                      textOverflow: "ellipsis",
                    }}
                  />
                  <IconButton onClick={() => editAction(action.uuid)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => deleteAction(action.uuid)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography>There are no actions applied to this form</Typography>
          )}
        </AccordionDetails>
      </Accordion>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: "5px" }}>
        <Button
          sx={{ mt: "15px" }}
          variant="contained"
          color="primary"
          onClick={() => {
            setShowJsonStructure((state) => !state);
          }}
        >
          View Components
        </Button>
        <Button
          sx={{ mt: "15px" }}
          variant="contained"
          color="primary"
          onClick={() => {
            handlePreview({
              components: props.components,
              layout: props.layout,
              rules: props.rules,
              actions: props.actions,
            });
          }}
        >
          Preview Form
        </Button>
        <Button
          sx={{ mt: "15px" }}
          variant="contained"
          color="primary"
          onClick={props.handleSave}
        >
          Save Template
        </Button>
      </Box>

      {/* {showRuleBuilder && (
        <OverlayComponent
          onCancel={CloseHandler}
          onClose={CloseHandler}
          onOk={SaveHandler}
          title="Edit Rule"
        >
          <RuleEditor
            activeRule={activeRule}
            handleActiveRuleUpdate={handleActiveRuleUpdate}
            components={props.components}
          />
        </OverlayComponent>
      )} */}
    </Box>
  );
};

export default ComponentPalette;
