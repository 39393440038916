import { defaultTheme } from "react-admin";
import purple from "@mui/material/colors/purple";
import grey from "@mui/material/colors/grey";
import red from "@mui/material/colors/red";

export default {
  ...defaultTheme,
  palette: {
    mode: "light",
    primary: {
      main: purple[600], // main primary color
      contrastText: "#fff",
    },
    secondary: {
      main: grey[600], // secondary color
      contrastText: "#fff",
    },
    error: red,
    background: {
      default: "#f4f4f4", // light gray background
    },
    text: {
      primary: "#333333", // very dark gray for primary text
      secondary: "#555555", // medium dark gray for secondary text
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  components: {
    ...defaultTheme.components,
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: "outlined",
      },
    },
  },
};
