import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
// import { Box } from "@mui/system";
import { Box, Paper } from "@mui/material";

import { allowedFunctions } from "./constants";
//custom component imports
import PreviewTextField from "./previewComponents/PreviewTextField";
import PreviewFileUpload from "./previewComponents/PreviewFileUpload";
import PreviewFileReference from "./previewComponents/PreviewFileReference";
import PreviewRadioGroup from "./previewComponents/PreviewRadioGroup";
import PreviewSelect from "./previewComponents/PreviewSelect";
import PreviewCheckbox from "./previewComponents/PreviewCheckbox";
import PreviewHtml from "./previewComponents/PreviewHtml";
import PreviewParagraph from "./previewComponents/PreviewParagraph";
import PreviewInteger from "./previewComponents/PreviewInteger";
import PreviewFloat from "./previewComponents/PreviewFloat";
// import PreviewTemplateReference from "./previewComponents/PreviewTemplateReference";
import PreviewFileAttachment from "./previewComponents/PreviewFileAttachment";
import PreviewDateField from "./previewComponents/PreviewDate";
import { useDataProvider } from "ra-core";
import { cloneDeep, isEqual } from "lodash";

const worker = new Worker("worker.js");
const worker2 = new Worker("worker2.js");

function safelyEvaluateUserScript(userCode, components) {
  return new Promise((resolve, reject) => {
    // Send the user code and components to the worker
    worker.postMessage({ userCode, components });

    // Listen for a message back from the worker
    worker.onmessage = function (e) {
      if (e.data.error) {
        reject(e.data.error);
      } else {
        // console.log("PENIIIIIIIIIIIIIIIS", e.data.result);
        resolve(e.data.result);
      }
    };

    worker.onerror = function (e) {
      reject(e.message);
    };
  });
}

function executeActionOnComponents(userCode, components) {
  return new Promise((resolve, reject) => {
    // Create a new worker and post a message to it with the components object and user code
    worker2.postMessage({ userCode, components });
    // Listen for a message from the worker containing the manipulated components object
    worker2.onmessage = (event) => {
      // console.log(event, "FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF");
      if (event.data.result !== undefined) {
        const manipulatedComponents = event.data.result;
        resolve(manipulatedComponents);
      } else if (event.data.error !== undefined) {
        reject(new Error(event.data.error));
      }
    };
    worker2.onerror = (errorEvent) => {
      reject(new Error(errorEvent.message));
    };
  });
}

function debounce(fn, delay) {
  let timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    const args = arguments;
    const that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
}

function hasVisibleComponents(item, components) {
  if (item.type === "component") {
    return !components[item.id].props.invisible;
  }
  return item.children.some((elem) => hasVisibleComponents(elem, components));
}

const ComponentPalette = ({
  component,
  debouncedUpdate,
  referenceCount,
  handleComponentUpdate,
}) => {
  let renderedComponent;
  const props = component.props;
  if (props.invisible) {
    return null;
  }
  switch (component.type) {
    case "date-field":
      renderedComponent = (
        <PreviewDateField
          propsUpdate={handleComponentUpdate}
          props={props}
          componentId={component.id}
        />
      );
      break;
    case "text-field":
      renderedComponent = (
        <PreviewTextField
          propsUpdate={debouncedUpdate}
          props={props}
          componentId={component.id}
        />
      );
      break;
    case "file-upload":
      renderedComponent = (
        <PreviewFileUpload
          propsUpdate={handleComponentUpdate}
          props={props}
          componentId={component.id}
        />
      );
      break;
    case "file-reference":
      renderedComponent = (
        <PreviewFileReference
          propsUpdate={debouncedUpdate}
          props={props}
          componentId={component.id}
        />
      );
      break;
    case "radio-group":
      renderedComponent = (
        <PreviewRadioGroup
          propsUpdate={handleComponentUpdate}
          props={props}
          componentId={component.id}
        />
      );
      break;
    case "select":
      renderedComponent = (
        <PreviewSelect
          propsUpdate={handleComponentUpdate}
          props={props}
          componentId={component.id}
        />
      );
      break;
    case "checkbox":
      renderedComponent = (
        <PreviewCheckbox
          propsUpdate={handleComponentUpdate}
          props={props}
          componentId={component.id}
        />
      );
      break;
    case "custom-html":
      renderedComponent = (
        <PreviewHtml
          propsUpdate={debouncedUpdate}
          props={props}
          componentId={component.id}
        />
      );
      break;
    case "paragraph":
      renderedComponent = (
        <PreviewParagraph
          propsUpdate={debouncedUpdate}
          props={props}
          componentId={component.id}
        />
      );
      break;
    case "integer":
      renderedComponent = (
        <PreviewInteger
          propsUpdate={debouncedUpdate}
          props={props}
          componentId={component.id}
        />
      );
      break;
    case "float":
      renderedComponent = (
        <PreviewFloat
          propsUpdate={debouncedUpdate}
          props={props}
          componentId={component.id}
        />
      );
      break;
    case "template-reference":
      renderedComponent = (
        // <PreviewTemplateReference referenceCount={referenceCount} {...props} />
        <FormPreviewer
          referenceCount={referenceCount - 1}
          fetchId={props.templateId}
        />
      );
      break;
    case "file-attachment":
      renderedComponent = (
        <PreviewFileAttachment
          propsUpdate={handleComponentUpdate}
          props={props}
          componentId={component.id}
        />
      );
      break;
    default:
      renderedComponent = null;
  }

  return (
    <Box>
      <div style={{ display: "flex", alignItems: "center" }}>
        {component.props.label.EN}
        {component.props.required && (
          <span style={{ color: "red", marginLeft: "5px" }}>*</span>
        )}
      </div>
      <div>{renderedComponent}</div>
    </Box>
  );
};

function RenderLayout({
  layout,
  components,
  debouncedUpdate,
  referenceCount,
  handleComponentUpdate,
}) {
  return layout.map((item) => {
    if (!hasVisibleComponents(item, components)) {
      return null;
    }
    if (item.type === "row") {
      return (
        <Box
          key={item.id}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            "& > *": {
              marginRight: 2, // Adjust space between items
            },
            marginBottom: 2, // Space below each row
          }}
        >
          <RenderLayout
            layout={item.children}
            components={components}
            debouncedUpdate={debouncedUpdate}
            handleComponentUpdate={handleComponentUpdate}
            referenceCount={referenceCount}
          />
        </Box>
      );
    }
    if (item.type === "column") {
      return (
        <Box
          key={item.id}
          sx={{
            display: "flex",
            flexDirection: "column",
            "& > *": {
              marginBottom: 2, // Space between items in a column
            },
            width: "100%", // Column takes full width
          }}
        >
          <Paper
            elevation={2} // Adds a subtle shadow to each component
            sx={{
              padding: 2, // Padding inside each paper
              borderRadius: 1, // Rounded corners
              width: "100%", // Each paper takes full width of the column
              boxSizing: "border-box", // Ensures padding doesn't add to the width
            }}
          >
            {item.children.map((child) => (
              <ComponentPalette
                debouncedUpdate={debouncedUpdate}
                handleComponentUpdate={handleComponentUpdate}
                key={child.id}
                component={components[child.id]}
                referenceCount={referenceCount}
              />
            ))}
          </Paper>
        </Box>
      );
    }
    return null;
  });
}

// function ApplyRules(components, rules, allowedFunctions) {
//   const newComponents = JSON.parse(JSON.stringify(components));
//   rules.forEach((rule) => {
//     let isRuleTrue = false;
//     if (rule.affectedComponent && newComponents[rule.affectedComponent]) {
//       rule.rows.forEach((row) => {
//         if (row.type === "attribute") {
//           let statement;
//           switch (row.attributeSign) {
//             case "=":
//               statement = newComponents[row.componentId][row.key] == row.value;
//               if (statement) isRuleTrue = true;
//               break;
//             case "&lt;":
//               statement = newComponents[row.componentId][row.key] < row.value;
//               if (statement) isRuleTrue = true;
//               break;
//             case "&gt;":
//               statement = newComponents[row.componentId][row.key] > row.value;
//               if (statement) isRuleTrue = true;
//               break;
//             case "&gt;=":
//               statement = newComponents[row.componentId][row.key] >= row.value;
//               if (statement) isRuleTrue = true;
//               break;
//             case "&lt;=":
//               statement = newComponents[row.componentId][row.key] <= row.value;
//               if (statement) isRuleTrue = true;
//               break;
//             case "!=":
//               statement = newComponents[row.componentId][row.key] != row.value;
//               if (statement) isRuleTrue = true;
//               break;
//             default:
//               isRuleTrue = false;
//               break;
//           }
//         } else if (row.type === "javascript") {
//           isRuleTrue = safelyEvaluateUserScript(
//             row.value,
//             newComponents,
//             allowedFunctions
//           );
//         }
//       });
//     }
//     if (isRuleTrue) {
//       switch (rule.cellBehavior) {
//         case "hidden":
//           newComponents[rule.affectedComponent].props.invisible = true;
//           break;
//         case "read-only":
//           newComponents[rule.affectedComponent].props.disabled = true;
//           break;
//         case "required":
//           newComponents[rule.affectedComponent].props.required = true;
//           break;
//         case "editable":
//           newComponents[rule.affectedComponent].props.disabled = false;
//           newComponents[rule.affectedComponent].props.invisible = false;
//           break;
//         default:
//           break;
//       }
//     } else {
//       switch (rule.elseBehavior) {
//         case "hidden":
//           newComponents[rule.affectedComponent].props.invisible = true;
//           break;
//         case "read-only":
//           newComponents[rule.affectedComponent].props.disabled = true;
//           break;
//         case "required":
//           newComponents[rule.affectedComponent].props.required = true;
//           break;
//         case "editable":
//           newComponents[rule.affectedComponent].props.disabled = false;
//           newComponents[rule.affectedComponent].props.invisible = false;
//           break;
//         default:
//           break;
//       }
//     }
//   });
//   return newComponents;
// }

function evaluateAttributeCondition(row, components) {
  const component = components[row.componentId];
  if (!component) return false;
  // console.log(row.attributeSign, "THE ATTRIBUTE SIIIIIIIIIIIIIIIIIIIIIIIIIGN");

  switch (row.attributeSign) {
    case "=":
      return component.props[row.key] == row.value;
    case "&lt;":
      return component.props[row.key] < row.value;
    case "&gt;":
      return component.props[row.key] > row.value;
    case "&gt;=":
      return component.props[row.key] >= row.value;
    case "&lt;=":
      return component.props[row.key] <= row.value;
    case "!=":
      return component.props[row.key] != row.value;
    default:
      return false;
  }
}

async function ApplyRules(
  components,
  originalComponents,
  rules,
  allowedFunctions
) {
  const newComponents = JSON.parse(JSON.stringify(components));

  for (const rule of rules) {
    if (rule.affectedComponent && newComponents[rule.affectedComponent]) {
      let isRuleTrue = true;

      for (let i = 0; i < rule.rows.length; i++) {
        const row = rule.rows[i];
        let conditionMet = false;

        try {
          if (row.type === "attribute") {
            conditionMet = evaluateAttributeCondition(row, newComponents);
          } else if (row.type === "javascript") {
            conditionMet = await safelyEvaluateUserScript(
              row.value,
              newComponents,
              allowedFunctions
            );
          }
        } catch (error) {
          console.error("Error in rule evaluation:", error);
          conditionMet = false; // or handle appropriately
        }

        if (i === 0) {
          isRuleTrue = conditionMet;
        } else {
          if (row.operator === "AND") {
            isRuleTrue = isRuleTrue && conditionMet;
          } else if (row.operator === "OR") {
            isRuleTrue = isRuleTrue || conditionMet;
          }
        }
      }

      applyBehaviors(rule, isRuleTrue, newComponents, originalComponents);
    }
  }

  return newComponents;
}

function applyBehaviors(rule, isRuleTrue, components, originalComponents) {
  const behavior = isRuleTrue ? rule.cellBehavior : rule.elseBehavior;
  const component = components[rule.affectedComponent];
  const originalComponent = originalComponents[rule.affectedComponent];
  if (component && originalComponent) {
    switch (behavior) {
      case "hidden":
        component.props.disabled = originalComponent.props.disabled;
        component.props.required = originalComponent.props.required;
        component.props.invisible = true;
        break;
      case "read-only":
        component.props.required = originalComponent.props.required;
        component.props.invisible = originalComponent.props.invisible;
        component.props.disabled = true;
        break;
      case "required":
        component.props.invisible = originalComponent.props.invisible;
        component.props.disabled = originalComponent.props.disabled;
        component.props.required = true;
        break;
      case "editable":
        component.props.requried = originalComponent.props.required;
        component.props.disabled = false;
        component.props.invisible = false;
        break;
      default:
        break;
    }
  }
}

const FormPreviewer = ({ referenceCount, fetchId }) => {
  const dataProvider = useDataProvider();
  // const [templateData, setTemplateData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const counter = referenceCount ?? 3;
  const location = useLocation();
  const [components, setComponents] = useState({});
  const [originalComponents, setOriginalComponents] = useState({});
  const [rules, setRules] = useState([]);
  const [layout, setLayout] = useState([]);
  const [actions, setActions] = useState([]);
  // const prevComponentsRef = useRef({});
  // const isFirstLoadRef = useRef(true);
  const isFirstLoadRef = useRef(true);

  // Ref to track the previous components state
  const prevComponentsRef = useRef({});
  // useEffect(() => {
  //   prevComponentsRef.current = components;
  // }, [components]);

  const queryParams = new URLSearchParams(location.search);
  const previewType = queryParams.get("type");
  const templateId = fetchId ?? queryParams.get("templateId");

  useEffect(() => {
    const fetchData = async () => {
      if (counter > 0) {
        try {
          setIsLoading(true);
          const response = await dataProvider.getOne(previewType, {
            id: templateId,
          });
          const parsedData = JSON.parse(response.data.template_JSON);
          setComponents(parsedData.components);
          setOriginalComponents(cloneDeep(parsedData.components));
          setRules(parsedData.rules);
          setLayout(parsedData.layout);
          setActions(parsedData.actions);
        } catch (error) {
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [templateId, referenceCount, dataProvider]);

  // useEffect(() => {
  //   const handleOnLoadActions = async () => {
  //     let updatedComponents = cloneDeep(components);

  //     for (const action of actions) {
  //       if (action.actionBehavior["on-load"]) {
  //         updatedComponents = await executeActionOnComponents(
  //           updatedComponents,
  //           action.actionJS
  //         );
  //       }
  //     }

  //     setComponents(updatedComponents);
  //   };
  //   handleOnLoadActions();
  // }, [actions]); // Empty dependency array for onLoad

  // useEffect(() => {
  //   // Check each action to see if it needs to be executed
  //   const handleActions = async () => {
  //     let updatedComponents = { ...components };
  //     let hasUpdates = false;

  //     for (const action of actions) {
  //       if (action.actionBehavior["on-update"]) {
  //         const affectedComponentIds = action.affectedComponent.split(",");

  //         // Check if any of the affected components have changed
  //         const hasChanged = affectedComponentIds.some(
  //           (id) => !isEqual(prevComponentsRef.current[id], components[id])
  //         );

  //         if (hasChanged) {
  //           updatedComponents = await executeActionOnComponents(
  //             updatedComponents,
  //             action.actionJS
  //           );
  //           hasUpdates = true;
  //         }
  //       }
  //     }

  //     // Update state and ref if there were updates
  //     if (hasUpdates) {
  //       setComponents(updatedComponents);
  //     }
  //   };

  //   handleActions();
  // }, [actions, components]);

  // useEffect(() => {
  //   prevComponentsRef.current = components;
  // }, [components]);

  const handleSave = async () => {
    let updatedComponents = cloneDeep(components);

    // On-save actions
    for (const action of actions) {
      if (action.actionBehavior["on-save"]) {
        updatedComponents = await executeActionOnComponents(
          action.actionJS,
          updatedComponents
        );
      }
    }

    setComponents(updatedComponents);
  };

  const handleComponentUpdate = (componentId, newProps) => {
    const currentState = cloneDeep(components); // Clone the current state
    prevComponentsRef.current = currentState; // Update the ref to the current state
    setComponents((prevComponents) => {
      // Create a copy for the new state
      const updatedComponents = {
        ...prevComponents,
        [componentId]: {
          ...prevComponents[componentId],
          props: {
            ...prevComponents[componentId].props,
            ...newProps,
          },
        },
      };

      // Return the new state
      return updatedComponents;
    });
  };
  const debouncedUpdate = debounce(handleComponentUpdate, 750);

  useEffect(() => {
    const applyRulesAndActions = async () => {
      try {
        // Apply rules
        let updatedComponents = await ApplyRules(
          components,
          originalComponents,
          rules,
          allowedFunctions
        );

        // Check for 'onLoad' actions only on the first load
        if (
          isFirstLoadRef.current &&
          actions.length > 0 &&
          Object.keys(components).length > 0
        ) {
          for (const action of actions) {
            if (action.actionBehavior["on-load"]) {
              updatedComponents = await executeActionOnComponents(
                action.actionJS,
                updatedComponents
              );
            }
          }
          isFirstLoadRef.current = false; // Set to false after processing first load actions
        }

        // Check for 'onUpdate' actions
        for (const action of actions) {
          if (action.actionBehavior["on-update"]) {
            const affectedComponentIds = action.affectedComponent.split(",");
            const hasChanged = affectedComponentIds.some(
              (id) =>
                !isEqual(prevComponentsRef.current[id], updatedComponents[id])
            );

            if (hasChanged) {
              updatedComponents = await executeActionOnComponents(
                action.actionJS,
                updatedComponents
              );
            }
          }
        }

        // Update the components state if there were changes
        // console.log(
        //   "HERE BEFORE THE UPDATE RUNSssssssssssssssssssssssssssssssssssssssssssssssssssssssssss",
        //   components,
        //   updatedComponents
        // );
        if (!isEqual(components, updatedComponents)) {
          prevComponentsRef.current = cloneDeep(components);
          setComponents(updatedComponents);
        }
      } catch (error) {
        console.error("Error applying rules or actions:", error);
      }
    };

    // prevComponentsRef.current = cloneDeep(components);
    applyRulesAndActions();
  }, [components, rules, actions]);

  if (counter <= 0) {
    return <div>Maximum template nesting reached.</div>;
  }

  if (isLoading) {
    return <div>Loading referenced template...</div>;
  }

  return Object.keys(components).length > 0 ? (
    <RenderLayout
      debouncedUpdate={debouncedUpdate}
      handleComponentUpdate={handleComponentUpdate}
      layout={layout}
      components={components}
      referenceCount={counter}
    />
  ) : (
    <div>Loading...</div>
  );
};

export default FormPreviewer;
