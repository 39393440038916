import TextField from "@mui/material/TextField";
import { cloneDeep } from "lodash";
import { useState, useEffect } from "react";

const PreviewFloat = ({ propsUpdate, props, componentId }) => {
  const safeParseNumber = (value, defaultValue) => {
    if (value === "") return defaultValue;
    const number = parseFloat(value);
    return isNaN(number) ? defaultValue : number;
  };

  // Set default values and ensure correct types
  const defaultProps = {
    value: props.value || "",
    min: safeParseNumber(props.min, Number.MIN_SAFE_INTEGER),
    max: safeParseNumber(props.max, Number.MAX_SAFE_INTEGER),
    precision: safeParseNumber(props.precision, 2), // Default precision
    required: !!props.required,
    disabled: !!props.disabled,
    placeholder: props.placeholder || "",
  };

  // Convert string to number for precision
  defaultProps.precision = Number(defaultProps.precision);

  const [floatValue, setFloatValue] = useState(defaultProps.value);

  useEffect(() => {
    setFloatValue(defaultProps.value);
  }, [
    defaultProps.value,
    defaultProps.min,
    defaultProps.max,
    defaultProps.precision,
    defaultProps.required,
    defaultProps.disabled,
    defaultProps.placeholder,
  ]);

  const handleChange = (event) => {
    const newValue = parseFloat(event.target.value) || 0;
    setFloatValue(newValue);

    // Use cloneDeep to avoid mutating original props
    const updatedProps = cloneDeep(defaultProps);
    updatedProps.value = newValue;
    propsUpdate(componentId, updatedProps);
  };

  const step = 1 / Math.pow(10, defaultProps.precision);

  return (
    <TextField
      type="number"
      onChange={handleChange}
      value={floatValue}
      variant="outlined"
      required={defaultProps.required}
      disabled={defaultProps.disabled}
      placeholder={defaultProps.placeholder}
      InputProps={{
        inputProps: {
          step: step,
          min: defaultProps.min,
          max: defaultProps.max,
        },
      }}
    />
  );
};

export default PreviewFloat;
