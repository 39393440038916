import { COMPONENT, ROW, COLUMN, DEFAULT_PROPS } from "./Constants";
import { v4 as uuid } from "uuid";

const initialData = {
  layout: [
    {
      type: ROW,
      id: "row0",
      children: [
        {
          type: COLUMN,
          id: "column0",
          children: [
            {
              type: COMPONENT,
              id: "component0",
            },
            {
              type: COMPONENT,
              id: "component1",
            },
          ],
        },
        {
          type: COLUMN,
          id: "column1",
          children: [
            {
              type: COMPONENT,
              id: "component2",
            },
          ],
        },
      ],
    },
    // {
    //   type: ROW,
    //   id: "row1",
    //   children: [
    //     {
    //       type: COLUMN,
    //       id: "column2",
    //       children: [
    //         {
    //           type: COMPONENT,
    //           id: "component3",
    //         },
    //         {
    //           type: COMPONENT,
    //           id: "component0",
    //         },
    //         {
    //           type: COMPONENT,
    //           id: "component2",
    //         },
    //       ],
    //     },
    //   ],
    // },
  ],
  components: {
    component0: {
      id: "component0",
      type: "paragraph",
      // content: "Some input",
      props: { ...DEFAULT_PROPS["paragraph"] },
    },
    component1: {
      id: "component1",
      type: "paragraph",
      // content: "Some image",
      props: { ...DEFAULT_PROPS["paragraph"] },
    },
    component2: {
      id: "component2",
      type: "paragraph",
      // content: "Some email",
      props: { ...DEFAULT_PROPS["paragraph"] },
    },
    // component3: {
    //   id: "component3",
    //   type: "paragraph",
    //   // content: "Some name",
    //   props: { ...DEFAULT_PROPS["paragraph"] },
    // },
    // component4: {
    //   id: "component4",
    //   type: "paragraph",
    //   // content: "Some phone",
    //   props: { ...DEFAULT_PROPS["paragraph"] },
    // },
  },
  rules: [],
  actions: [],
};

export default initialData;
