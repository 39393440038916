import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";

const PreviewParagraph = ({ propsUpdate, props, componentId }) => {
  // Set default values and ensure correct types
  const safeParseNumber = (value, defaultValue) => {
    if (value === "") return defaultValue;
    const number = parseInt(value, 10);
    return isNaN(number) ? defaultValue : number;
  };

  // Set default values and ensure correct types
  const defaultProps = {
    value: props.value || "",
    placeholder: props.placeholder || "",
    required: !!props.required,
    disabled: !!props.disabled,
    maxLength: safeParseNumber(props.maxLength, 10000), // Default to a large number if maxLength isn't defined
  };

  const [paragraphContent, setParagraphContent] = useState(
    defaultProps.value
  );

  useEffect(() => {
    setParagraphContent(defaultProps.value);
  }, [
    defaultProps.value,
    defaultProps.placeholder,
    defaultProps.required,
    defaultProps.disabled,
    defaultProps.maxLength,
  ]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setParagraphContent(newValue);

    // Call propsUpdate to notify parent component of the change
    propsUpdate(componentId, { ...defaultProps, value: newValue });
  };

  return (
    <TextField
      multiline
      rows={4}
      placeholder={defaultProps.placeholder}
      variant="outlined"
      disabled={defaultProps.disabled}
      required={defaultProps.required}
      value={paragraphContent}
      onChange={handleChange}
      inputProps={{ maxLength: defaultProps.maxLength }}
    />
  );
};

export default PreviewParagraph;
