import {
  TextField,
  Switch,
  FormControlLabel,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { cloneDeep } from "lodash";
import { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import TemplateList from "./TemplateReferenceHelper";
// import { useDataProvider } from "ra-core";
import { useDataProvider } from "react-admin";
import CopyToClipboardButton from "../helperComponents/CopyToClipboardButton";
import { useMatch } from "react-router-dom";

const TemplateReferenceEdit = forwardRef(({ component }, ref) => {
  const match = useMatch("/:type/:id");
  const [localProps, setLocalProps] = useState(component.props);
  const [templateReferenceData, setTemplateReferenceData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Added
  const [pageSize, setPageSize] = useState(5); // Added

  const handleTemplateSelect = (newTemplate) => {
    handleChange("template", newTemplate);
  };

  useImperativeHandle(ref, () => ({
    getProps: () => ({ localProps, componentId: component.id }),
  }));

  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider
      .getList(match.params.type, {
        pagination: { page: currentPage, perPage: pageSize }, // Updated
        sort: { field: "template_ID", order: "ASC" },
      })
      .then((response) => {
        setTemplateReferenceData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage, pageSize]);

  const handleChange = (type, value) => {
    const newProps = cloneDeep(localProps);
    if (type.includes(".")) {
      const splitType = type.split(".");
      newProps.label[splitType[1]] = value;
      setLocalProps(newProps);
      return;
    }
    switch (type) {
      case "XPATH":
        newProps.XPATH = value;
        break;
      case "disabled":
        newProps.disabled = value;
        break;
      case "required":
        newProps.required = value;
        break;
      case "invisible":
        newProps.invisible = value;
        break;
      case "template":
        newProps.templateId = value.templateId;
        newProps.templateName = value.templateName;
        break;
      //   case "reference":
      //     newProps.reference = value;
      //     break;
      //   case "placeholder":
      //     newProps.placeholder = value;
      //     break;
      default:
        return;
    }
    setLocalProps(newProps);
  };

  // const handleDropdownChange = (e) => {
  //   setShowLanguages(e.target.value === "Show Languages");
  // };

  return (
    <div style={{ width: "100%", overflow: "auto" }}>
      <TextField
        sx={{ borderRadius: 0 }}
        label="XPATH"
        value={localProps.XPATH}
        onChange={(e) => handleChange("XPATH", e.target.value)}
        fullWidth
      />
      <CopyToClipboardButton textToCopy={component.id} />
      <FormControlLabel
        control={
          <Switch
            checked={localProps.disabled}
            onChange={(e) => handleChange("disabled", e.target.checked)}
          />
        }
        label="Disabled"
      />
      <FormControlLabel
        control={
          <Switch
            checked={localProps.required}
            onChange={(e) => handleChange("required", e.target.checked)}
          />
        }
        label="Required"
      />
      <FormControlLabel
        control={
          <Switch
            checked={localProps.invisible}
            onChange={(e) => handleChange("invisible", e.target.checked)}
          />
        }
        label="Invisible"
      />
      {/* <TextField
        sx={{ borderRadius: 0 }}
        label="Value"
        value={localProps.reference}
        onChange={(e) => handleChange("reference", e.target.value)}
        fullWidth
      />
      <TextField
        sx={{ borderRadius: 0 }}
        label="Placeholder"
        value={localProps.placeholder}
        onChange={(e) => handleChange("placeholder", e.target.value)}
        fullWidth
      /> */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Template reference</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: "column" }}>
          <TemplateList
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            templates={templateReferenceData}
            handleTemplateSelect={handleTemplateSelect}
            pageSize={pageSize} // Added
            setPageSize={setPageSize} // Added
            selectedTemplate={localProps.templateId}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          // sx={{
          // //   "&.MuiAccordionSummary-root": {
          // //     margin: "20px", // you can adjust this as needed
          // //     minHeight: "initial", // to overwrite the min height if it's the case
          // //   },
          //   "&.Mui-expanded": {
          //     minHeight: "initial", // to overwrite the min height when expanded
          //   },
          // //   "& .MuiAccordionSummary-content": {
          // //     margin: 0, // again, adjust this as needed
          // //   },
          //   "&.Mui-expanded .MuiAccordionSummary-content": {
          //     margin: 0, // this is when the summary is in expanded state
          //   },
          // }}
          // style={{ padding: 0, margin: 0 }}
        >
          <Typography>Languages</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: "column" }}>
          {Object.entries(localProps.label).map(([lang, text]) => (
            <TextField
              sx={{ borderRadius: 0 }}
              key={lang}
              label={`Label (${lang})`}
              value={text}
              onChange={(e) => handleChange(`label.${lang}`, e.target.value)}
              fullWidth
            />
          ))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
});

export default TemplateReferenceEdit;
