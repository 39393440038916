import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControlLabel,
  //   InputLabel,
  FormControl,
  Box,
  Typography,
  InputLabel,
  Checkbox,
  FormGroup,
  FormLabel,
} from "@mui/material";

const JavascriptAction = ({
  components,
  activeAction,
  handleActiveActionUpdate,
}) => {
  const checkComponentDependencies = (dependencyString) => {
    const dependencies = dependencyString.split(",").map((dep) => dep.trim());
    return dependencies.every((dep) => components.hasOwnProperty(dep));
  };

  const isDependencyValid = checkComponentDependencies(
    activeAction.affectedComponent
  );

  const handleCheckboxChange = (event) => {
    // Get the name of the checkbox (e.g., "on-load") and its checked status
    const { name, checked } = event.target;

    // Call handleActiveActionUpdate with the checkbox name and its checked status
    handleActiveActionUpdate("actionBehavior", { key: name, value: checked });
  };

  return (
    <div>
      <TextField
        label="Action Name"
        value={activeAction.actionName}
        onChange={(e) => handleActiveActionUpdate("actionName", e.target.value)}
      />
      <TextField
        sx={{
          margin: "10px",
          "& .MuiInputBase-root": {
            backgroundColor: isDependencyValid ? "lightgreen" : "inherit",
          },
        }}
        helperText="Enter dependency components' ids separated by comma"
        value={activeAction.affectedComponent}
        label="Component dependency"
        onChange={(event) => {
          const trimmedValue = event.target.value.replace(/\s+/g, "");
          handleActiveActionUpdate("affectedComponent", trimmedValue);
        }}
        margin="dense"
      />
      {/* <FormControl
        sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <InputLabel>Action Behavior</InputLabel>
        <Select
          value={activeAction.actionBehavior}
          onChange={(e) =>
            handleActiveActionUpdate("actionBehavior", e.target.value)
          }
        >
          <MenuItem value={"on-load"}>On Load</MenuItem>
          <MenuItem value={"on-update"}>On Update</MenuItem>
          <MenuItem value={"on-save"}>On Save</MenuItem>
        </Select>
      </FormControl> */}
      <FormControl component="fieldset">
        <FormLabel component="legend">Action Behavior</FormLabel>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={activeAction.actionBehavior["on-load"]}
                onChange={handleCheckboxChange}
                name="on-load"
              />
            }
            label="On Load"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={activeAction.actionBehavior["on-update"]}
                onChange={handleCheckboxChange}
                name="on-update"
              />
            }
            label="On Update"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={activeAction.actionBehavior["on-save"]}
                onChange={handleCheckboxChange}
                name="on-save"
              />
            }
            label="On Save"
          />
        </FormGroup>
      </FormControl>

      <br />
      <Box
        display="flex"
        flexDirection="column"
        //   alignItems="center"
        justifyContent="center"
      >
        <TextField
          sx={{
            "& textarea": {
              resize: "both",
              overflow: "auto",
              maxHeight: "400px",
              maxWidth: "1000px",
            },
          }}
          multiline
          rows={5}
          value={activeAction.actionJS}
          placeholder="Enter JS code"
          onChange={(e) => handleActiveActionUpdate("actionJS", e.target.value)}
          margin="dense"
        />
        <Typography>
          Make sure your script assings correct values to components.
        </Typography>
      </Box>
    </div>
  );
};

export default JavascriptAction;
