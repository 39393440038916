import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  Button,
  Box,
  Card,
  CardContent,
  IconButton,
  Typography,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { useDataProvider } from "react-admin";
import CloseIcon from "@mui/icons-material/Close";

const CompanyEdit = () => {
  const dataProvider = useDataProvider();
  const inputRef = useRef(null);

  const [data, setData] = useState({
    companyName: "",
    avatar: "",
    description: "",
    isPublic: false,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [imageCleared, setImageCleared] = useState(false);

  useEffect(() => {
    const fetchCompany = async () => {
      try {
        const response = await dataProvider.getList("company", {
          pagination: { page: 1, perPage: 1 },
          sort: { field: "id", order: "ASC" },
          filter: {},
        });
        setData({
          companyName: response.data[0].companyName,
          avatar: response.data[0].avatar,
          description: response.data[0].description,
          isPublic: response.data[0].isPublic, // Set based on fetched data
        });
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };

    fetchCompany();
  }, [dataProvider]);

  const handleInputChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onloadend = () => {
      setData((prevState) => ({ ...prevState, avatar: reader.result }));
      setImageCleared(false);
    };
    reader.readAsDataURL(file);
  };

  const handleFileInputClick = () => {
    inputRef.current.click();
  };

  const handleSave = async () => {
    try {
      const formData = new FormData();

      // Append text data
      formData.append("companyName", data.companyName);
      formData.append("description", data.description);
      formData.append("isPublic", data.isPublic);

      // If the avatar is a dataURL, convert it to File object (blob)
      if (data.avatar && data.avatar.startsWith("data:")) {
        const response = await fetch(data.avatar);
        const blob = await response.blob();
        const file = new File([blob], "avatar.png", { type: "image/png" });
        formData.append("avatar", file);
      }
      formData.append("delete_avatar", imageCleared);

      // Use dataProvider to update the data
      await dataProvider.update("company", { id: data.id, data: formData });

      // console.log("Data updated successfully!");
    } catch (err) {
      console.error("Failed to update data:", err);
    }
  };

  const clearImage = () => {
    setData((prevState) => ({ ...prevState, avatar: "" }));
    setImageCleared(true);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <Card elevation={3} style={{ height: "100vh" }}>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 3,
            height: "100%",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h4"
            component="div"
            sx={{ fontWeight: "bold", color: "#333" }}
          >
            {data.companyName}
          </Typography>
          <Box sx={{ position: "relative", marginBottom: 3 }}>
            {data.avatar && (
              <img
                src={data.avatar}
                alt="Company Logo"
                style={{
                  width: "200px",
                  height: "200px",
                  objectFit: "cover",
                  borderRadius: "15px",
                }}
              />
            )}
            {data.avatar && (
              <IconButton
                onClick={clearImage}
                size="small"
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.6)", // semi-transparent black
                  color: "white",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.8)", // darker on hover
                  },
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </Box>

          <Button variant="outlined" onClick={handleFileInputClick}>
            Choose Image
          </Button>
          <input
            style={{ display: "none" }}
            ref={inputRef}
            type="file"
            accept="image/*"
            onChange={handleImageChange}
          />

          <TextField
            name="description"
            label="Description"
            multiline
            rows={4}
            variant="outlined"
            fullWidth
            value={data.description || ""}
            onChange={handleInputChange}
          />
          <FormControlLabel
            control={
              <Switch
                checked={data.isPublic}
                onChange={(e) =>
                  setData({ ...data, isPublic: e.target.checked })
                }
                name="isPublic"
              />
            }
            label="IS COMPANY PUBLIC:"
            labelPlacement="start"
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            style={{ marginTop: "24px" }}
            onClick={handleSave}
          >
            Save
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CompanyEdit;
