import {
  List,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from "@mui/material";

const TemplateList = ({
  templates,
  pageSize,
  setPageSize,
  currentPage,
  setCurrentPage,
  selectedTemplate,
  handleTemplateSelect,
}) => {
  const handleNext = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  // Function to go to the previous page
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };
  return (
    <div>
      <List>
        {templates.slice(0, pageSize).map((template) => (
          <ListItemButton
            // button
            key={template.template_ID}
            selected={selectedTemplate === template.template_ID} // newly added
            onClick={() => {
              handleTemplateSelect({
                templateId: template.template_ID,
                templateName: template.template_Name,
              });
            }}
          >
            <ListItemAvatar>
              <Avatar
                alt={template.template_Name}
                src={template.thumbnailUrl}
                style={{ borderRadius: 0 }}
              />
            </ListItemAvatar>
            <ListItemText primary={template.template_Name} />
          </ListItemButton>
        ))}
      </List>
      <FormControl
        variant="outlined"
        style={{ width: "200px", marginBottom: "16px", marginRight: "8px" }}
      >
        <InputLabel id="page-size-label">Items per Page</InputLabel>
        <Select
          labelId="page-size-label"
          id="page-size-select"
          value={pageSize}
          onChange={(e) => setPageSize(e.target.value)}
          label="Items per Page"
        >
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={50}>50</MenuItem>
        </Select>
      </FormControl>
      <Box display="flex" alignItems="center" flexWrap="wrap">
        <Button
          onClick={handlePrevious}
          variant="contained"
          color="primary"
          style={{ width: "100px" }}
        >
          Previous
        </Button>

        <Button
          variant="contained"
          color="primary"
          style={{ width: "100px", marginLeft: 5 }}
          onClick={handleNext}
        >
          Next
        </Button>
      </Box>
    </div>
  );
};

export default TemplateList;
