import { DEFAULT_PROPS } from "./Constants";
// Check if two objects have the same keys and values are of same types
function deepTypeCheck(obj1, obj2) {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // If number of properties is different, objects are not equivalent
  if (keys1.length !== keys2.length) {
    console.log("Mismatch in number of properties");
    return false;
  }

  for (const key of keys1) {
    const val1 = obj1[key];
    const val2 = obj2[key];
    const areObjects = isObject(val1) && isObject(val2);
    const areArrays = Array.isArray(val1) && Array.isArray(val2);

    if (areArrays) {
      // Array validation logic; could be more elaborate depending on use case
      continue;
    }

    if (areObjects && !deepTypeCheck(val1, val2)) {
      console.log(`Mismatch found in object properties at key '${key}'`);
      return false;
    } else if (!areObjects && typeof val1 !== typeof val2) {
      console.log(`Type mismatch at key '${key}': ${typeof val1} vs ${typeof val2}`);
      return false;
    }
  }

  return true;
}

function isObject(object) {
  return object != null && typeof object === "object";
}

// Validate layout recursively
function getReferenceObject(type) {
  if (type === "component") {
    return { type: "", id: "" };
  } else if (type === "row" || type === "column") {
    return { type: "", id: "", children: [] };
  }
  return null;
}

// Validate layout recursively
function validateLayout(layout) {
  if (!Array.isArray(layout)) return false;

  for (const item of layout) {
    const referenceObject = getReferenceObject(item.type);
    if (!referenceObject) {
      return false;
    }

    // console.log(item, referenceObject);
    if (!deepTypeCheck(item, referenceObject)) {
      return false;
    }
    // console.log("the current item passed the check", item);

    if (item.children && !validateLayout(item.children)) {
      return false;
    }
    // console.log("the current item passed the check", item);
  }
  return true;
}

export function validateResponse(template_JSON) {
  // if (!response || !response.template_JSON) {
  //   throw new Error("Invalid response or template_JSON not found");
  // }
  // console.log(template_JSON, "why is this bullshit failing");

  const { layout, components } = template_JSON;

  // Validate layout
  if (!validateLayout(layout, components)) {
    throw new Error("Invalid layout structure");
  }

  // Validate components
  for (const id in components) {
    const component = components[id];
    if (
      !component ||
      !component.type ||
      !component.id ||
      !component.props ||
      !deepTypeCheck(component.props, DEFAULT_PROPS[component.type])
    ) {
      console.error(component);
      return false;
    }
  }

  return true;
}
