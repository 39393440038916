import { Box, Button } from "@mui/material";

const CustomFileUpload = ({
  accept,
  maxSize,
  multiple,
  disabled,
  required,
}) => (
  <Box sx={{ padding: "10px" }}>
    <input
      disabled={disabled}
      required={required}
      id="file-upload"
      type="file"
      accept={accept}
      maxsize={Number(maxSize)}
      multiple={multiple}
      hidden
    />
    <label htmlFor="file-upload">
      <Button variant="contained" component="span">
        Upload File
      </Button>
    </label>
  </Box>
);

export default CustomFileUpload;
