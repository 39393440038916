import styled from "@emotion/styled";

const StyledMenu = styled.div`
  position: absolute;
  left: ${(props) => props.x}px;
  top: ${(props) => props.y}px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
`;

const StyledButton = styled.button`
  display: block;
  background: none;
  border: none;
  padding: 8px 16px;
  width: 100%;
  text-align: left;

  &:hover {
    background-color: #007bff;
    color: white;
  }
`;

export const DeleteButton = ({ x, y, onDelete }) => (
  <StyledMenu x={x} y={y}>
    <StyledButton onClick={onDelete}>Delete</StyledButton>
    <StyledButton onClick={() => console.log("Edit clicked")}>
      Edit
    </StyledButton>
    <StyledButton onClick={() => console.log("Duplicate clicked")}>
      Duplicate
    </StyledButton>
  </StyledMenu>
);