import {
  FormControl,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from "@mui/material";
import { cloneDeep } from "lodash";
import { useState, useEffect } from "react";

const PreviewCheckbox = ({ propsUpdate, props, componentId }) => {
  const [checkedValues, setCheckedValues] = useState(props.value || []);

  const handleChange = (event) => {
    const newCheckedValues = cloneDeep(checkedValues);
    if (event.target.checked) {
      newCheckedValues.push(event.target.value);
    } else {
      const index = newCheckedValues.indexOf(event.target.value);
      if (index > -1) {
        newCheckedValues.splice(index, 1);
      }
    }
    setCheckedValues(newCheckedValues);

    const newProps = cloneDeep(props);
    newProps.value = newCheckedValues;
    propsUpdate(componentId, newProps);
  };

  // Synchronize internal state with external prop changes
  useEffect(() => {
    setCheckedValues(props.value || []);
  }, [props.value]);

  const { options, required, disabled } = props;

  return (
    <FormControl required={required} disabled={disabled} component="fieldset">
      <FormGroup>
        {options.map((option, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={checkedValues.includes(option.value)}
                onChange={handleChange}
                value={option.value}
                disabled={disabled}
              />
            }
            label={option.label.EN}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

export default PreviewCheckbox;
