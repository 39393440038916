import * as React from "react";
import { useState } from "react";
import { useLogin, useNotify, Notification } from "react-admin";
import {
  Button,
  TextField,
  Paper,
  Typography,
  Container,
  Divider,
} from "@mui/material";
import MicrosoftIcon from "@mui/icons-material/Microsoft";
import { useDispatch } from "react-redux";
import { loginActions } from "./store/loginSlice";

const MyLoginPage = ({
  theme,
  setLocalAuth,
  setAzureAuth,
  setAzureData,
  setLocalData,
}) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  let loginMethod = "local";
  const login = useLogin();
  const notify = useNotify();

  // const handleLogin = (method) => async (dispatch, getState) => {
  //   if (method === "local") {
  //     dispatch(loginActions.setLocal());
  //   } else if (method === "azure") {
  //     dispatch(loginActions.setSSO());
  //   }

  //   // Here, you can now ensure the state has been updated and then perform login
  //   const updatedState = getState();
  //   if (updatedState.login.loginMethod === "azure") {
  //     // Call Azure login
  //   } else {
  //     // Call local login
  //   }
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    login({ email, password, loginMethod }).catch(() =>
      notify("Authentication error")
    );
  };

  const handleLocalLogin = (event) => {
    dispatch(loginActions.setLocal());
    setLocalAuth();
    // setAzureData();
    loginMethod = "local";
    handleSubmit(event);
  };

  const handleSSOLogin = (event) => {
    dispatch(loginActions.setSSO());
    setAzureAuth();
    // setAzureData();
    loginMethod = "azure";
    handleSubmit(event);
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        bg: "background.default",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          p: 3,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form sx={{ width: "100%", mt: 3 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleLocalLogin}
          >
            Login
          </Button>

          <Divider sx={{ my: 2 }}>OR</Divider>

          <Button
            // type="submit"
            onClick={handleSSOLogin}
            fullWidth
            variant="outlined"
            startIcon={<MicrosoftIcon style={{ fill: "#0078d4" }} />} // Add the logo before the text
            sx={{
              borderColor: "#0078d4",
              color: "#0078d4",
              mt: 3,
              mb: 2,
            }}
          >
            Login with Microsoft account
          </Button>
        </form>
      </Paper>
      <Notification />
    </Container>
  );
};

export default MyLoginPage;
