import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  DeleteButton,
  ImageField,
  BooleanField,
} from "react-admin";

const TaskTemplateList = (props) => {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source="template_ID" label="ID" />
        <ImageField source="thumbnailUrl" label="Thumbnail" />
        <TextField source="template_Name" label="Task Name" />
        <TextField source="template_Description" label="Task Description" />
        <BooleanField source="isReferenceable" label="Is Referenceable" />
        {/* <TextField source="template_JSON" label="Task JSON" /> */}
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

export default TaskTemplateList;
