import { fetchUtils } from "ra-core";
import simpleRestProvider from "ra-data-simple-rest";
import store from "./components/store";
import { msalInstance } from "./App";
import { loginRequest } from "./components/authConfig";
// import { getHttpClient } from "./httpClient";

const apiUrl = "https://nalipka.azurewebsites.net/azuredata";

// console.log("WHY IS THIS BULLSHIT RUNNING LMAO 22222");

// const httpClient = (url, options = {}) => {
//   if (!options.headers) {
//     options.headers = new Headers({ Accept: "application/json" });
//   }
//   const token = store.getState((state) => state.login.accessToken);

//   options.headers.set("Authorization-Local", `Bearer ${token}`);

//   options.credentials = "include";

//   return fetchUtils.fetchJson(url, options);
// };

const createDataProvider = () => {
  const httpClient = async (url, options = {}) => {
    if (!options.headers) {
      options.headers = new Headers({ Accept: "application/json" });
    }
    // if (loginMethod === "local") {
    const state = store.getState();
    const token = state.login.accessToken;
    options.headers.set("Authorization-Local", `Bearer ${token}`);
    // } else {
    // const response = await msalInstance.acquireTokenSilent({
    // scopes: loginRequest.scopes,
    // account: msalInstance.getAllAccounts()[0],
    // });
    // const token = response.accessToken;
    // options.headers.set("Authorization-Azure", `Bearer ${token}`);
    // }
    options.credentials = "include";
    return fetchUtils.fetchJson(url, options);
  };
  const baseDataProvider = simpleRestProvider(apiUrl, httpClient);
  return {
    ...baseDataProvider,
    getOne: async (resource, params) => {
      const response = await baseDataProvider.getOne(resource, params);
      // console.log("API response:", response);
      if (resource === "companies") {
        const dataValues = response.data;

        response.data = {
          id: dataValues.company_ID,
          companyName: dataValues.companyName,
          // apiToken: dataValues.apiToken,
        };
      }
      if (resource === "users") {
        const dataValues = response.data;

        response.data = {
          id: dataValues.user_ID,
          email: dataValues.email,
          first_Name: dataValues.first_Name,
          second_Name: dataValues.second_Name,
          // registration_Date: dataValues.registration_Date,
          isActive: dataValues.isActive,
          verified_Login: dataValues.verified_Login,
        };
      }
      // if (resource === "company-role-user") {
      //   const dataValues = response.data;

      //   response.data = {
      //     id: dataValues.id,
      //     companyName: dataValues.companyName,
      //     company_ID: dataValues.company_ID,
      //     role_Name: dataValues.role_Name,
      //     role_ID: dataValues.role_ID,
      //     email: dataValues.email,
      //     user_ID: dataValues.user_ID,
      //   };
      // }
      if (resource === "task-templates") {
        const dataValues = response.data;

        response.data = {
          id: dataValues.task_ID,
          ...dataValues,
          // companyName: dataValues.companyName,
          // apiToken: dataValues.apiToken,
        };
      }

      if (resource === "request-templates") {
        const dataValues = response.data;

        response.data = {
          id: dataValues.task_ID,
          ...dataValues,
          // companyName: dataValues.companyName,
          // apiToken: dataValues.apiToken,
        };
      }

      if (resource === "roles") {
        const dataValues = response.data.dataValues;
        // console.log("these are the data values", dataValues);

        response.data = {
          id: dataValues.role_ID,
          role_Name: dataValues.role_Name,
          role_ID: dataValues.role_ID,
        };
      }
      return response;
    },
    getMany: async (resource, params) => {
      // console.log("but we're actually here loooool!!!");
      const response = await baseDataProvider.getMany(resource, params);
      // console.log(response);
      if (resource === "users") {
        return {
          ...response,
          data: response.data.map((user) => ({
            id: user.user_ID, // This maps user_ID to id
            ...user,
          })),
        };
      }
    },
    getList: async (resource, params) => {
      const response = await baseDataProvider.getList(resource, params);
      // console.log(response, "WE'RE DOING FINE!", resource);
      if (resource === "company-role-user") {
        return {
          ...response,
          data: response.data.map((record) => ({
            id: record.id,
            companyName: record.company.companyName,
            email: record.user.email,
            role_Name: record.role.role_Name,
            createdAt: record.createdAt,
          })),
        };
      }

      if (resource === "companies") {
        return {
          ...response,
          data: response.data.map((company) => ({
            ...company,
            id: company.company_ID,
          })),
        };
      }
      if (resource === "users") {
        // console.log("hello there!");
        return {
          ...response,
          data: response.data.map((user) => ({
            ...user,
            id: user.user_ID,
          })),
        };
      }
      if (resource === "roles") {
        return {
          ...response,
          data: response.data.map((role) => ({
            ...role,
            id: role.role_ID,
          })),
        };
      }
      if (resource === "tasks") {
        return {
          ...response,
          data: response.data.map((task) => ({
            ...task,
            id: task.task_ID,
          })),
        };
      }

      if (resource === "task-templates" || resource === "request-templates") {
        return {
          ...response,
          data: response.data.map((template) => ({
            ...template,
            id: template.template_ID,
          })),
        };
      }

      if (resource === "company") {
        return {
          ...response,
          data: response.data.map((company) => ({
            ...company,
            id: company.company_ID,
          })),
        };
      }

      return response;
    },
    update: async (resource, params) => {
      if (resource === "company") {
        const options = {
          method: "PUT",
          body: params.data,
        };

        const response = await httpClient(`${apiUrl}/${resource}`, options);
        return { data: { ...params.data, id: response.json.id } };
      }
      if (resource === "task-templates") {
        const options = {
          method: "PUT",
          body: params.data,
        };

        const response = await httpClient(
          `${apiUrl}/${resource}/${params.id}`,
          options
        );
        // console.log("FUCKING RESPONSE AAAAAAAAAAAAAAAAAAAAAAA", response);
        return { data: { ...response.json } };
      }
      if (resource === "request-templates") {
        const options = {
          method: "PUT",
          body: params.data,
        };

        const response = await httpClient(
          `${apiUrl}/${resource}/${params.id}`,
          options
        );
        // console.log("FUCKING RESPONSE AAAAAAAAAAAAAAAAAAAAAAA", response);
        return { data: { ...response.json } };
      }
      // ... handle other resources ...
      const response = await baseDataProvider.update(resource, params);
      const data = response.data;
      return { data };
    },
    create: async (resource, params) => {
      if (
        ((resource === "task-templates" || resource === "request-templates") &&
          (params.data.task_JSON || params.data.thumbnail)) ||
        (resource === "user-requests" && params.data.request_JSON)
      ) {
        const formData = new FormData();

        Object.keys(params.data).forEach((key) => {
          const value = params.data[key];

          if (value === undefined || value === null) {
            return; // Skip this key if it has no value
          }

          const isFileField =
            ["task_JSON", "request_JSON", "thumbnail"].includes(key) &&
            value.rawFile;

          if (isFileField) {
            formData.append(key, value.rawFile);
          } else {
            formData.append(key, value);
          }
        });

        const options = {
          method: "POST",
          body: formData,
        };

        // for (var pair of formData.entries()) {
        //   console.log(pair[0] + ", " + pair[1]);
        // }

        const response = await httpClient(`${apiUrl}/${resource}`, options);
        return { data: { ...params.data, id: response.json.id } };
      }

      const response = await baseDataProvider.create(resource, params);
      const data = response.data;

      // console.log(response);
      // console.log(data);

      return { data };
    },
  };
};
export default createDataProvider;

// const baseDataProvider = simpleRestProvider(apiUrl, getHttpClient());

// const dataProvider = {
//   ...baseDataProvider,

// };

// export default dataProvider;
