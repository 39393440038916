import TextField from "@mui/material/TextField";

const CustomTextField = ({
  placeholder,
  value,
  disabled,
  required,
  errorMessage,
  maxLength,
}) => (
  <TextField
    // label={placeholder}
    value={value}
    variant="outlined"
    // defaultValue={value}
    disabled={disabled}
    required={required}
    placeholder={placeholder}
    // helperText={errorMessage}
    //error={!!errorMessage}
    inputProps={{ maxLength: Number(maxLength) }}
  />
);
export default CustomTextField;
