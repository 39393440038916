import {
  TextField,
  Switch,
  FormControlLabel,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { cloneDeep } from "lodash";
import { useState, forwardRef, useImperativeHandle } from "react";
import CopyToClipboardButton from "../helperComponents/CopyToClipboardButton";

const IntegerEdit = forwardRef(({ component }, ref) => {
  const [localProps, setLocalProps] = useState(component.props);

  useImperativeHandle(ref, () => ({
    getProps: () => ({ localProps, componentId: component.id }),
  }));

  // const {
  //   label,
  //   placeholder,
  //   value,
  //   disabled,
  //   required,
  //   errorMessage,
  //   maxLength,
  //   XPATH,
  // } = component.props;

  const handleChange = (type, value) => {
    const newProps = cloneDeep(localProps);
    if (type.includes(".")) {
      const splitType = type.split(".");
      newProps.label[splitType[1]] = value;
      setLocalProps(newProps);
      return;
    }
    switch (type) {
      case "XPATH":
        newProps.XPATH = value;
        break;
      case "disabled":
        newProps.disabled = value;
        break;
      case "required":
        newProps.required = value;
        break;
      case "invisible":
        newProps.invisible = value;
        break;
      case "value":
        newProps.value = value;
        break;
      case "min":
        newProps.min = value;
        break;
      case "max":
        newProps.max = value;
        break;
      case "placeholder":
        newProps.placeholder = value;
        break;
      default:
        return;
    }
    setLocalProps(newProps);
  };

  // const handleDropdownChange = (e) => {
  //   setShowLanguages(e.target.value === "Show Languages");
  // };

  return (
    <div style={{ width: "100%", overflow: "auto" }}>
      <TextField
        sx={{ borderRadius: 0 }}
        label="XPATH"
        value={localProps.XPATH}
        onChange={(e) => handleChange("XPATH", e.target.value)}
        fullWidth
      />
      <CopyToClipboardButton textToCopy={component.id} />
      <FormControlLabel
        control={
          <Switch
            checked={localProps.disabled}
            onChange={(e) => handleChange("disabled", e.target.checked)}
          />
        }
        label="Disabled"
      />
      <FormControlLabel
        control={
          <Switch
            checked={localProps.required}
            onChange={(e) => handleChange("required", e.target.checked)}
          />
        }
        label="Required"
      />
      <FormControlLabel
        control={
          <Switch
            checked={localProps.invisible}
            onChange={(e) => handleChange("invisible", e.target.checked)}
          />
        }
        label="Invisible"
      />
      <TextField
        sx={{ borderRadius: 0 }}
        inputProps={{ min: localProps.min, max: localProps.max }}
        label="Value"
        type="number"
        value={localProps.value}
        onChange={(e) => handleChange("value", e.target.value)}
        fullWidth
      />
      <TextField
        sx={{ borderRadius: 0 }}
        label="Min value"
        type="number"
        value={localProps.min}
        onChange={(e) => handleChange("min", e.target.value)}
        fullWidth
      />
      <TextField
        sx={{ borderRadius: 0 }}
        label="Max value"
        type="number"
        value={localProps.max}
        onChange={(e) => handleChange("max", e.target.value)}
        fullWidth
      />
      <TextField
        sx={{ borderRadius: 0 }}
        label="Placeholder"
        value={localProps.placeholder}
        onChange={(e) => handleChange("placeholder", e.target.value)}
        fullWidth
      />
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          // sx={{
          // //   "&.MuiAccordionSummary-root": {
          // //     margin: "20px", // you can adjust this as needed
          // //     minHeight: "initial", // to overwrite the min height if it's the case
          // //   },
          //   "&.Mui-expanded": {
          //     minHeight: "initial", // to overwrite the min height when expanded
          //   },
          // //   "& .MuiAccordionSummary-content": {
          // //     margin: 0, // again, adjust this as needed
          // //   },
          //   "&.Mui-expanded .MuiAccordionSummary-content": {
          //     margin: 0, // this is when the summary is in expanded state
          //   },
          // }}
          // style={{ padding: 0, margin: 0 }}
        >
          <Typography>Languages</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: "column" }}>
          {Object.entries(localProps.label).map(([lang, text]) => (
            <TextField
              sx={{ borderRadius: 0 }}
              key={lang}
              label={`Label (${lang})`}
              value={text}
              onChange={(e) => handleChange(`label.${lang}`, e.target.value)}
              fullWidth
            />
          ))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
});

export default IntegerEdit;
