import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
} from "react-admin";

const TaskTemplateCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <ImageInput
          multiple={false}
          accept="image/jpeg,image/png,image/gif"
          source="thumbnail"
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <TextInput source="template_Name" />
        <TextInput source="template_Description" />
        {/* <ReferenceInput source="user_ID" reference="users">
          <SelectInput optionText="email" />
        </ReferenceInput>
        <ReferenceInput source="company_ID" reference="companies">
          <SelectInput optionText="companyName" />
        </ReferenceInput> 
         <FileInput
          multiple={false}
          accept="application/json"
          source="task_JSON"
        >
          <FileField source="src" title="title" />
        </FileInput> */}
      </SimpleForm>
    </Create>
  );
};

export default TaskTemplateCreate;
