import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { cloneDeep } from "lodash";

const PreviewTextField = ({ propsUpdate, props, componentId }) => {
  const safeParseNumber = (value, defaultValue) => {
    if (value === "") return defaultValue;
    const number = parseInt(value, 10);
    return isNaN(number) ? defaultValue : number;
  };
  // Set default values and ensure correct types
  const defaultProps = {
    value: props.value || "",
    placeholder: props.placeholder || "",
    disabled: !!props.disabled,
    required: !!props.required,
    errorMessage: props.errorMessage || "",
    maxLength: safeParseNumber(props.maxLength, 255), // Default maxLength
  };

  // Ensure maxLength is a number
  defaultProps.maxLength = Number(defaultProps.maxLength);

  const [textValue, setTextValue] = useState(defaultProps.value);

  useEffect(() => {
    setTextValue(defaultProps.value);
  }, [
    defaultProps.value,
    defaultProps.placeholder,
    defaultProps.disabled,
    defaultProps.required,
    defaultProps.errorMessage,
    defaultProps.maxLength,
  ]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setTextValue(newValue);

    // Use cloneDeep to avoid mutating original props
    const updatedProps = cloneDeep(defaultProps);
    updatedProps.value = newValue;
    propsUpdate(componentId, updatedProps);
  };

  return (
    <TextField
      value={textValue}
      onChange={handleChange}
      variant="outlined"
      disabled={defaultProps.disabled}
      required={defaultProps.required}
      placeholder={defaultProps.placeholder}
      // helperText={defaultProps.errorMessage}
      // error={!!defaultProps.errorMessage}
      inputProps={{ maxLength: defaultProps.maxLength }}
    />
  );
};

export default PreviewTextField;
