import {
  FormControl,
  FormControlLabel,
  // FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

const CustomRadioGroup = ({ value, options, required, disabled }) => (
  <FormControl required={required} disabled={disabled} component="fieldset">
    {/* <FormLabel component="legend">Radio Group</FormLabel> */}
    <RadioGroup value={value}>
      {options.map((option, index) => (
        <FormControlLabel
          key={index}
          value={option.value}
          control={<Radio />}
          label={option.label.EN}
        />
      ))}
    </RadioGroup>
  </FormControl>
);

export default CustomRadioGroup;
