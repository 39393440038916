// in src/MyMenu.js
import { Menu } from "react-admin";
// import LabelIcon from "@mui/icons-material/Label";
// import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
// import TaskIcon from "@mui/icons-material/Task";
// import GroupIcon from "@mui/icons-material/Group";
// import RequestPageIcon from "@mui/icons-material/RequestPage";
import NoteAddIcon from "@mui/icons-material/NoteAdd";

export const MyMenu = () => (
  <Menu>
    {/* <Menu.ResourceItem name="company-role-users" /> */}
    {/* <Menu.ResourceItem name="roles" /> */}
    <Menu.ResourceItem name="task-templates" />
    <Menu.ResourceItem name="request-templates" />
    <Menu.ResourceItem name="users" />
    {/* <Menu.ResourceItem name="user-requests" /> */}
    <Menu.ResourceItem name="company-admins" />
    <Menu.ResourceItem name="company" />
    {/* <Menu.ResourceItem name="user-role-company" /> */}
    {/* <Menu.Item
      to="/buildform"
      primaryText="Build Form"
      leftIcon={<NoteAddIcon />}
    /> */}
  </Menu>
);

export default MyMenu;
