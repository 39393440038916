import React from "react";
import {
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  BooleanInput,
} from "react-admin";

const CompanyAdminCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceInput source="user_ID" reference="users">
          <SelectInput
            optionValue="user_ID"
            label="User"
            optionText="user.email"
          />
        </ReferenceInput>
        <BooleanInput source="isSuper" label="Is Super Admin" />
      </SimpleForm>
    </Create>
  );
};

export default CompanyAdminCreate;
