import React, { useState, useEffect } from 'react';
import TextField from "@mui/material/TextField";

const PreviewDateField = ({ propsUpdate, props, componentId }) => {
  const [dateValue, setDateValue] = useState(props.value || '');

  const handleChange = (event) => {
    const newValue = event.target.value;
    setDateValue(newValue);

    // Clone props and update value before sending for external update
    const newProps = { ...props, value: newValue };
    propsUpdate(componentId, newProps);
  };

  // Synchronize internal state with external prop changes
  useEffect(() => {
    setDateValue(props.value || '');
  }, [props.value]);

  const { min, max, required, disabled } = props;

  return (
    <TextField
      type="date"
      value={dateValue}
      onChange={handleChange}
      required={required}
      disabled={disabled}
      min={min}
      max={max}
      variant="outlined"
      InputLabelProps={{ shrink: true }}
    />
  );
};

export default PreviewDateField;