import React from "react";
import { Typography, Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
// import { useDrop } from "react-dnd";
import DraggableFormComponent from "./DraggableFormComponent";
import DropZone from "./essentials/DropZone";
import { SIDEBAR_ITEM, COMPONENT, ROW, COLUMN } from "./Constants";
import Row from "./essentials/Row";

const FormBuilderArea = (props) => {
  const renderRow = (row, currentPath) => {
    return (
      <Row
        key={row.id}
        data={row}
        handleDrop={props.handleDrop}
        components={props.components}
        path={currentPath}
        handleDelete={props.handleDelete}
        // setIdOfSelectedComponent={props.setIdOfSelectedComponent}
        // toggleFieldEditor={props.toggleFieldEditor}
        selectComponent={props.selectComponent}
      />
    );
  };
  const ACCEPTS = [SIDEBAR_ITEM, COMPONENT, ROW, COLUMN];

  // const [, drop] = useDrop(() => ({
  //   accept: ACCEPTS,
  // }));

  return (
    <Box
      // ref={drop}
      sx={{
        flex: 1,
        padding: 2,
        backgroundColor: "grey.100",
        minHeight: "100vh",
        border: "1px solid #ddd",
        // borderRadius: 2,
        // marginLeft: 2,
      }}
    >
      <Typography variant="h6">Form Builder Area:</Typography>
      {/* {console.log(props.layout)} */}
      {/* {console.log(props.components)} */}
      {props.layout.length > 0 &&
        props.layout.map((row, index) => {
          const currentPath = `${index}`;
          return (
            <React.Fragment key={row.id}>
              <DropZone
                data={{
                  path: currentPath,
                  childrenCount: props.layout.length,
                }}
                onDrop={props.handleDrop}
                path={currentPath}
              />
              {renderRow(row, currentPath)}
            </React.Fragment>
          );
        })}
      <DropZone
        data={{
          path: `${props.layout.length}`,
          childrenCount: props.layout.length,
        }}
        onDrop={props.handleDrop}
        isLast
      />
    </Box>
  );
};

export default FormBuilderArea;
