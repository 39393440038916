import React, { useState } from 'react';
import { Box, Button, Typography, IconButton } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';

const PreviewFileUpload = ({ propsUpdate, props }) => {
  const { accept, maxSize, multiple, disabled, required } = props;
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);
    // Update parent component
    // propsUpdate(...) // Update this according to your requirements
  };

  const clearFile = (fileIndex) => {
    const newFiles = selectedFiles.filter((_, index) => index !== fileIndex);
    setSelectedFiles(newFiles);
    // Update parent component
    // propsUpdate(...) // Update this according to your requirements
  };

  return (
    <Box sx={{ padding: "10px" }}>
      <input
        disabled={disabled}
        required={required}
        id="file-upload"
        type="file"
        accept={accept}
        maxsize={Number(maxSize)}
        multiple={multiple}
        hidden
        onChange={handleFileChange}
      />
      <label htmlFor="file-upload">
        <Button variant="contained" component="span">
          Upload File
        </Button>
      </label>
      {selectedFiles.length > 0 && (
        <Box mt={2}>
          {selectedFiles.map((file, index) => (
            <Box key={index} display="flex" alignItems="center" mt={1}>
              <Typography variant="body2" sx={{ flexGrow: 1 }}>
                {file.name}
              </Typography>
              <IconButton onClick={() => clearFile(index)} size="small">
                <ClearIcon fontSize="inherit" />
              </IconButton>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default PreviewFileUpload;
