import React, { useState, useEffect } from 'react';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

const PreviewRadioGroup = ({ propsUpdate, props, componentId }) => {
  const [selectedValue, setSelectedValue] = useState(props.value);

  // Handle change in radio button selection
  const handleChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);

    // Update the parent component with the new value
    const newProps = {...props, value: newValue};
    propsUpdate(componentId, newProps);
  };

  // Synchronize internal state with external prop changes
  useEffect(() => {
    setSelectedValue(props.value);
  }, [props.value]);

  const { options, required, disabled } = props;

  return (
    <FormControl required={required} disabled={disabled} component="fieldset">
      <RadioGroup value={selectedValue} onChange={handleChange}>
        {options.map((option, index) => (
          <FormControlLabel
            key={index}
            value={option.value}
            control={<Radio />}
            label={option.label.EN}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default PreviewRadioGroup;
