import React, { useRef, useState } from "react";
import { useDrag } from "react-dnd";
import { ROW } from "../Constants";
import DropZone from "./DropZone";
import Column from "./Column";
import { Box } from "@mui/material";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { DeleteButton } from "./DeleteButton";

const Row = ({
  data,
  handleDrop,
  path,
  components,
  selectComponent,
  handleDelete,
}) => {
  const [deleteBtnPosition, setDeleteBtnPosition] = useState(null);
  const handleRightClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // Hide menu if it's already open
    if (deleteBtnPosition) {
      setDeleteBtnPosition(null);
      return;
    }

    // Get bounding box of the parent component
    const boundingBox = ref.current.getBoundingClientRect();

    const x = e.clientX - boundingBox.left; // clientX and clientY are relative to the viewport, so adjust for element's position
    const y = e.clientY - boundingBox.top;

    setDeleteBtnPosition({ x, y });
  };

  const handleDeleteClick = () => {
    // Call your delete function here
    // After deleting, remove the delete button
    handleDelete(null, { path });
    setDeleteBtnPosition(null);
  };

  const ref = useRef(null);

  const [{ isDragging }, drag] = useDrag({
    type: ROW,
    item: () => ({
      id: data.id,
      children: data.children,
      path,
    }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(ref);

  const renderColumn = (column, currentPath) => {
    return (
      <Column
        key={column.id}
        data={column}
        components={components}
        handleDrop={handleDrop}
        path={currentPath}
        selectComponent={selectComponent}
        handleDelete={handleDelete}
      />
    );
  };

  return (
    <Box
      ref={ref}
      position="relative" // add this
      className="base draggable row"
      sx={{
        opacity: isDragging ? 0 : 1,
        overflow: "auto",
        cursor: "move",
        border: "1px dashed black",
        borderRadius: 0,
        padding: "20px",
      }}
      onContextMenu={handleRightClick}
    >
      <DragHandleIcon
        style={{
          position: "absolute",
          top: "50%",
          left: "20px", // changed from 0 to 10px
          transform: "translateY(-50%)",
          cursor: "grab",
        }}
      />{" "}
      {/* update this */}
      <Box
        className="columns"
        sx={{
          display: "flex",
          flexDirection: "row",
          paddingLeft: "2em", // added this to make room for the drag handle
        }}
      >
        {data.children.map((column, index) => {
          const currentPath = `${path}-${index}`;

          return (
            <React.Fragment key={column.id}>
              <DropZone
                data={{
                  path: currentPath,
                  childrenCount: data.children.length,
                }}
                onDrop={handleDrop}
                className="horizontalDrag"
              />
              {renderColumn(column, currentPath)}
            </React.Fragment>
          );
        })}
        <DropZone
          data={{
            path: `${path}-${data.children.length}`,
            childrenCount: data.children.length,
          }}
          onDrop={handleDrop}
          className="horizontalDrag"
          isLast
        />
      </Box>
      {deleteBtnPosition && (
        <DeleteButton
          x={deleteBtnPosition.x}
          y={deleteBtnPosition.y}
          onDelete={handleDeleteClick}
        />
      )}
    </Box>
  );
};
export default Row;