import React from "react";
import DOMPurify from "dompurify";
import parse from "html-react-parser";

const HtmlCustom = ({ value, required, disabled }) => {
  // Sanitize the HTML before rendering it
  let renderValue = value;
  if (renderValue === "") {
    renderValue = "<div>Custom html is empty</div>";
  }
  const cleanHTML = DOMPurify.sanitize(renderValue);

  return (
    <div
      // Render the sanitized HTML
      //   dangerouslySetInnerHTML={{ __html: cleanHTML }}
      // You can also apply other attributes as needed
      aria-required={required}
      aria-disabled={disabled}
    >
      {parse(cleanHTML)}
    </div>
  );
};

export default HtmlCustom;
