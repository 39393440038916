import {
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  TextField,
  IconButton,
  FormControlLabel,
  Switch,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { cloneDeep } from "lodash";
import { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import { v4 as uuid } from "uuid";
import CopyToClipboardButton from "../helperComponents/CopyToClipboardButton";

const FileAttachmentEdit = forwardRef(({ component }, ref) => {
  const [localProps, setLocalProps] = useState(component.props);
  const [selectedFiles, setSelectedFiles] = useState(
    component.props.files || []
  );

  useEffect(() => {
    const newNames = selectedFiles.map((elem) => elem.fileName);
    handleChange("fileName", newNames);
  }, [selectedFiles]);

  const removeFile = (index) => {
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);
  };

  useImperativeHandle(ref, () => ({
    getProps: () => ({ localProps, componentId: component.id }),
  }));

  const handleChange = (type, value) => {
    const newProps = cloneDeep(localProps);
    if (type.includes(".")) {
      const splitType = type.split(".");
      newProps.label[splitType[1]] = value;
      setLocalProps(newProps);
      return;
    }
    switch (type) {
      case "XPATH":
        newProps.XPATH = value;
        break;
      //   case "multiple":
      //     newProps.multiple = value;
      //     break;
      case "fileName":
        // console.log(selectedFiles);
        newProps.files = selectedFiles;
        break;
      default:
        return;
    }
    setLocalProps(newProps);
  };

  return (
    <div style={{ width: "100%", overflow: "auto" }}>
      <TextField
        sx={{ borderRadius: 0 }}
        label="XPATH"
        value={localProps.XPATH}
        onChange={(e) => handleChange("XPATH", e.target.value)}
        fullWidth
      />
      <CopyToClipboardButton textToCopy={component.id} />
      {/* <FormControlLabel
        control={
          <Switch
            checked={localProps.multiple}
            onChange={(e) => handleChange("multiple", e.target.checked)}
          />
        }
        label="Multiple files"
      /> */}
      {/* <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Attach files</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: "column" }}>
          <input
            type="file"
            id="hidden-file-input"
            // multiple={localProps.multiple}
            style={{ display: "none" }}
            onChange={(e) => {
              const newFiles = Array.from(e.target.files);
              const newFileData = newFiles.map((file) => ({
                fileName: file.name,
                id: uuid(),
              }));
              setSelectedFiles((prevFiles) => [...prevFiles, ...newFileData]);
            }}
          />
          <label htmlFor="hidden-file-input">
            <Button
              variant="outlined"
              color="primary"
              component="span"
              startIcon={<CloudUploadIcon />}
            >
              Upload
            </Button>
          </label>
          <List>
            {selectedFiles.length === 0 ? (
              <ListItem>
                <ListItemText
                  primary="No files attached!"
                  primaryTypographyProps={{
                    style: { fontStyle: "italic", color: "#999" },
                  }}
                />
              </ListItem>
            ) : (
              selectedFiles.map((file, index) => (
                <ListItem
                  key={file.id}
                  style={{
                    border: "1px solid #ccc",
                    padding: "8px",
                    borderRadius: "4px",
                    marginBottom: "8px",
                  }}
                >
                  <ListItemText primary={file.fileName} />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => removeFile(index)}
                      color="secondary"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))
            )}
          </List>
        </AccordionDetails>
      </Accordion> */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Languages</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: "column" }}>
          {Object.entries(localProps.label).map(([lang, text]) => (
            <TextField
              sx={{ borderRadius: 0 }}
              key={lang}
              label={`Label (${lang})`}
              value={text}
              onChange={(e) => handleChange(`label.${lang}`, e.target.value)}
              fullWidth
            />
          ))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
});

export default FileAttachmentEdit;
