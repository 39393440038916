import {
  TextField,
  Switch,
  FormControlLabel,
  // Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  // Select,
  // MenuItem,
  // Checkbox,
  // ListItemText,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { cloneDeep } from "lodash";
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import {
  // FILE_TYPES,
  EXT_TYPES,
  VIDEO_TYPES,
  AUDIO_TYPES,
  IMAGE_TYPES,
} from "../Constants";
import CopyToClipboardButton from "../helperComponents/CopyToClipboardButton";

const FileUploadEdit = forwardRef(({ component }, ref) => {
  const [localProps, setLocalProps] = useState(component.props);

  useImperativeHandle(ref, () => ({
    getProps: () => ({ localProps, componentId: component.id }),
  }));

  // const {
  //   label,
  //   placeholder,
  //   value,
  //   disabled,
  //   required,
  //   errorMessage,
  //   maxLength,
  //   XPATH,
  // } = component.props;

  const handleChange = (type, value) => {
    const newProps = cloneDeep(localProps);
    if (type.includes(".")) {
      const splitType = type.split(".");
      newProps.label[splitType[1]] = value;
      setLocalProps(newProps);
      return;
    }
    switch (type) {
      case "XPATH":
        newProps.XPATH = value;
        break;
      case "disabled":
        newProps.disabled = value;
        break;
      case "required":
        newProps.required = value;
        break;
      case "invisible":
        newProps.invisible = value;
        break;
      case "maxSize":
        newProps.maxSize = value;
        break;
      case "accept":
        newProps.accept = value;
        break;
      case "multiple":
        newProps.multiple = value;
        break;
      default:
        return;
    }
    setLocalProps(newProps);
  };

  const [selectedFileTypes, setSelectedFileTypes] = useState(
    localProps.accept.split(",")
  );
  useEffect(() => {
    // Update localProps.accept whenever selectedFileTypes changes
    const newProps = cloneDeep(localProps);
    newProps.accept = selectedFileTypes.join(","); // Convert the array back to a comma-separated string
    setLocalProps(newProps);
  }, [selectedFileTypes]);
  // console.log(selectedFileTypes);

  return (
    <div style={{ width: "100%", overflow: "auto" }}>
      <TextField
        sx={{ borderRadius: 0 }}
        label="XPATH"
        value={localProps.XPATH}
        onChange={(e) => handleChange("XPATH", e.target.value)}
        fullWidth
      />
      <CopyToClipboardButton textToCopy={component.id} />
      <TextField
        sx={{ borderRadius: 0 }}
        label="Max file size in bytes"
        value={localProps.maxSize}
        type="number"
        onChange={(e) => handleChange("maxSize", e.target.value)}
        fullWidth
      />
      {/* <TextField
        sx={{ borderRadius: 0 }}
        label="Error Message"
        value={localProps.errorMessage}
        onChange={(e) => handleChange("errorMessage", e.target.value)}
        fullWidth
      /> */}
      <FormControlLabel
        control={
          <Switch
            checked={localProps.disabled}
            onChange={(e) => handleChange("disabled", e.target.checked)}
          />
        }
        label="Disabled"
      />
      <FormControlLabel
        control={
          <Switch
            checked={localProps.required}
            onChange={(e) => handleChange("required", e.target.checked)}
          />
        }
        label="Required"
      />
      <FormControlLabel
        control={
          <Switch
            checked={localProps.invisible}
            onChange={(e) => handleChange("invisible", e.target.checked)}
          />
        }
        label="Invisible"
      />
      <FormControlLabel
        control={
          <Switch
            checked={localProps.multiple}
            onChange={(e) => handleChange("multiple", e.target.checked)}
          />
        }
        label="Multiple files"
      />
      {/* <TextField
        sx={{ borderRadius: 0 }}
        label="Value"
        value={localProps.value}
        onChange={(e) => handleChange("value", e.target.value)}
        fullWidth
      /> */}
      {/* <TextField
        sx={{ borderRadius: 0 }}
        label="Placeholder"
        value={localProps.placeholder}
        onChange={(e) => handleChange("placeholder", e.target.value)}
        fullWidth
      /> */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>File Types</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: "column" }}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              Image types
            </AccordionSummary>
            <AccordionDetails style={{ flexDirection: "column" }}>
              {IMAGE_TYPES.map((type) => (
                <FormControlLabel
                  key={type.id}
                  control={
                    <Switch
                      checked={selectedFileTypes.indexOf(type.id) > -1}
                      onChange={(event) => {
                        if (event.target.checked) {
                          let myFilteredTypes;
                          if (type?.exclusive) {
                            myFilteredTypes = selectedFileTypes.filter(
                              (item) => {
                                const currentType = IMAGE_TYPES.find(
                                  (t) => t.id === item
                                );
                                return currentType?.category !== "image";
                              }
                            );
                            myFilteredTypes = [...myFilteredTypes, type.id];
                            setSelectedFileTypes(myFilteredTypes);
                          } else {
                            const foundElem = IMAGE_TYPES.find(
                              (elem) => elem.exclusive === true
                            );
                            let filteredFileTypes = selectedFileTypes.filter(
                              (elem) => elem !== foundElem.id
                            );
                            setSelectedFileTypes([
                              ...filteredFileTypes,
                              type.id,
                            ]);
                          }
                        } else {
                          setSelectedFileTypes(
                            selectedFileTypes.filter((item) => item !== type.id)
                          );
                        }
                      }}
                    />
                  }
                  label={type.name}
                />
              ))}
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              Audio types
            </AccordionSummary>
            <AccordionDetails style={{ flexDirection: "column" }}>
              {AUDIO_TYPES.map((type) => (
                <FormControlLabel
                  key={type.id}
                  control={
                    <Switch
                      checked={selectedFileTypes.indexOf(type.id) > -1}
                      onChange={(event) => {
                        if (event.target.checked) {
                          let myFilteredTypes;
                          if (type?.exclusive) {
                            myFilteredTypes = selectedFileTypes.filter(
                              (item) => {
                                const currentType = AUDIO_TYPES.find(
                                  (t) => t.id === item
                                );
                                return currentType?.category !== "audio";
                              }
                            );
                            myFilteredTypes = [...myFilteredTypes, type.id];
                            setSelectedFileTypes(myFilteredTypes);
                          } else {
                            const foundElem = AUDIO_TYPES.find(
                              (elem) => elem.exclusive === true
                            );
                            let filteredFileTypes = selectedFileTypes.filter(
                              (elem) => elem !== foundElem.id
                            );
                            setSelectedFileTypes([
                              ...filteredFileTypes,
                              type.id,
                            ]);
                          }
                        } else {
                          setSelectedFileTypes(
                            selectedFileTypes.filter((item) => item !== type.id)
                          );
                        }
                      }}
                    />
                  }
                  label={type.name}
                />
              ))}
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              Video types
            </AccordionSummary>
            <AccordionDetails style={{ flexDirection: "column" }}>
              {VIDEO_TYPES.map((type) => (
                <FormControlLabel
                  key={type.id}
                  control={
                    <Switch
                      checked={selectedFileTypes.indexOf(type.id) > -1}
                      onChange={(event) => {
                        if (event.target.checked) {
                          let myFilteredTypes;
                          if (type?.exclusive) {
                            myFilteredTypes = selectedFileTypes.filter(
                              (item) => {
                                const currentType = VIDEO_TYPES.find(
                                  (t) => t.id === item
                                );
                                return currentType?.category !== "video";
                              }
                            );
                            myFilteredTypes = [...myFilteredTypes, type.id];
                            setSelectedFileTypes(myFilteredTypes);
                          } else {
                            const foundElem = VIDEO_TYPES.find(
                              (elem) => elem.exclusive === true
                            );
                            let filteredFileTypes = selectedFileTypes.filter(
                              (elem) => elem !== foundElem.id
                            );
                            setSelectedFileTypes([
                              ...filteredFileTypes,
                              type.id,
                            ]);
                          }
                        } else {
                          setSelectedFileTypes(
                            selectedFileTypes.filter((item) => item !== type.id)
                          );
                        }
                      }}
                    />
                  }
                  label={type.name}
                />
              ))}
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              EXT types
            </AccordionSummary>
            <AccordionDetails style={{ flexDirection: "column" }}>
              {EXT_TYPES.map((type) => (
                <FormControlLabel
                  key={type.id}
                  control={
                    <Switch
                      checked={selectedFileTypes.indexOf(type.id) > -1}
                      onChange={(event) => {
                        if (event.target.checked) {
                          let myFilteredTypes;
                          if (type?.exclusive) {
                            myFilteredTypes = selectedFileTypes.filter(
                              (item) => {
                                const currentType = EXT_TYPES.find(
                                  (t) => t.id === item
                                );
                                return currentType?.category !== ".ext";
                              }
                            );
                            myFilteredTypes = [...myFilteredTypes, type.id];
                            setSelectedFileTypes(myFilteredTypes);
                          } else {
                            const foundElem = EXT_TYPES.find(
                              (elem) => elem.exclusive === true
                            );
                            let filteredFileTypes = selectedFileTypes.filter(
                              (elem) => elem !== foundElem.id
                            );
                            setSelectedFileTypes([
                              ...filteredFileTypes,
                              type.id,
                            ]);
                          }
                        } else {
                          setSelectedFileTypes(
                            selectedFileTypes.filter((item) => item !== type.id)
                          );
                        }
                      }}
                    />
                  }
                  label={type.name}
                />
              ))}
            </AccordionDetails>
          </Accordion>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          // sx={{
          // //   "&.MuiAccordionSummary-root": {
          // //     margin: "20px", // you can adjust this as needed
          // //     minHeight: "initial", // to overwrite the min height if it's the case
          // //   },
          //   "&.Mui-expanded": {
          //     minHeight: "initial", // to overwrite the min height when expanded
          //   },
          // //   "& .MuiAccordionSummary-content": {
          // //     margin: 0, // again, adjust this as needed
          // //   },
          //   "&.Mui-expanded .MuiAccordionSummary-content": {
          //     margin: 0, // this is when the summary is in expanded state
          //   },
          // }}
          // style={{ padding: 0, margin: 0 }}
        >
          <Typography>Languages</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: "column" }}>
          {Object.entries(localProps.label).map(([lang, text]) => (
            <TextField
              sx={{ borderRadius: 0 }}
              key={lang}
              label={`Label (${lang})`}
              value={text}
              onChange={(e) => handleChange(`label.${lang}`, e.target.value)}
              fullWidth
            />
          ))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
});

export default FileUploadEdit;
