import React from "react";
import { Edit, SimpleForm, TextInput, BooleanInput } from "react-admin";

const CompanyUserEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput disabled source="first_Name" />
      <TextInput disabled source="second_Name" />
      <TextInput disabled source="email" />
      <BooleanInput source="isActive" label="Is Active" />
    </SimpleForm>
  </Edit>
);

export default CompanyUserEdit;
