import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loginMethod: "local",
  accessToken: "",
};
const loginSlice = createSlice({
  initialState,
  name: "login",
  reducers: {
    setLocal: (state) => {
      state.loginMethod = "local";
      return state;
    },
    setSSO: (state) => {
      state.loginMethod = "azure";
      return state;
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
      return state;
    },
    removeAccessToken: (state) => {
      state.accessToken = "";
      return state;
    },
  },
});

export const loginActions = loginSlice.actions;

export default loginSlice.reducer;
