import { msalInstance } from "./App";
import { loginRequest } from "./components/authConfig";
import { InteractionRequiredAuthError } from "@azure/msal-common";
import store from "./components/store";
import { loginActions } from "./components/store/loginSlice";

const azureAuthProvider = {
  login: async ({ email, password, loginMethod }) => {
    // const state = store.getState();
    // console.log(state, "THIS IS WHAT's BEING CALLED");
    if (loginMethod === "local") {
      const response = await fetch("https://nalipka.azurewebsites.net/azureauth/login", {
        method: "POST",
        body: JSON.stringify({ email, password }),
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      });

      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }

      const data = await response.json();
      store.dispatch(loginActions.setAccessToken(data.accessToken));
      return Promise.resolve();
    } else {
      try {
        const response = await msalInstance.loginPopup();

        // If login was successful, you'd have the response object which contains details about the logged-in user.
        // If you want to make additional logic/checks using the response, you can do it here.

        // Optionally: If you want to extract information from the response, you can do so. For example:
        // const user = response.account.username;
        // console.log(response);
        return Promise.resolve();
      } catch (error) {
        // If there's an error in the login process, this catch block will execute.
        // The error object will have details about what went wrong.
        console.error("Error during login:", error);

        // If you want to throw a custom error similar to the commented fetch logic:
        throw new Error(error.errorMessage || "Login failed");
      }
    }
  },
  logout: async () => {
    // return fetch("http://localhost:8080/adminauth/logout", {
    //   method: "POST",
    //   credentials: "include",
    // }).then(() => {});
    // console.log("LOGGING OUT, WILL ADD A MSAL REQUEST LATER");
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: async () => {
    try {
      const response = await msalInstance.acquireTokenSilent({
        scopes: loginRequest.scopes,
        account: msalInstance.getAllAccounts()[0],
      });
      const token = response.accessToken;

      const serverResponse = await fetch(
        "https://nalipka.azurewebsites.net/azureauth/check-auth",
        {
          method: "GET",
          headers: {
            "Authorization-Azure": `Bearer ${token}`,
          },
        }
      );

      if (serverResponse.status === 401) {
        throw new Error("Authentication failed");
      }
      if (serverResponse.status < 200 || serverResponse.status >= 300) {
        throw new Error("Network error");
      }
      return; // Resolved successfully
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        try {
          const response = await msalInstance.acquireTokenPopup(loginRequest);
          const token = response.accessToken;

          const serverResponse = await fetch(
            "https://nalipka.azurewebsites.net/azureauth/check-auth",
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (serverResponse.status === 401) {
            throw new Error("Authentication failed");
          }
          if (serverResponse.status < 200 || serverResponse.status >= 300) {
            throw new Error("Network error");
          }
          return; // Resolved successfully
        } catch (interactiveError) {
          throw interactiveError; // Rethrow the error from the inner catch
        }
      } else {
        throw error; // Rethrow the original error
      }
    }
  },
  getPermissions: () => {
    return Promise.resolve();
  },
};

export default azureAuthProvider;
