import React, { useState, useEffect } from 'react';
import { FormControl, MenuItem, Select } from "@mui/material";

const PreviewSelect = ({ propsUpdate, props, componentId }) => {
  const [selectValue, setSelectValue] = useState(props.value || '');

  useEffect(() => {
    // Synchronize internal state with external prop changes
    setSelectValue(props.value || '');
  }, [props.value]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setSelectValue(newValue);

    // Update the parent component's state
    const updatedProps = { ...props, value: newValue };
    propsUpdate(componentId, updatedProps);
  };

  return (
    <FormControl variant="outlined" fullWidth required={props.required} disabled={props.disabled}>
      <Select
        value={selectValue}
        onChange={handleChange}
        inputProps={{
          name: "custom-select",
          id: "custom-select",
        }}
      >
        {props.options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label.EN}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default PreviewSelect;
