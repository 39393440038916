import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  Filter,
  SearchInput,
  DateField,
  BooleanField,
} from "react-admin";

const AdminFilter = (props) => (
  <Filter {...props}>
    {/* <SearchInput placeholder="User ID" source="user_ID" resettable alwaysOn /> */}
    <SearchInput placeholder="Email" source="email" resettable alwaysOn />
    <SearchInput
      placeholder="First name"
      source="first_Name"
      resettable
      alwaysOn
    />
    {/* <SearchInput
      placeholder="Company ID"
      source="company_ID"
      resettable
      alwaysOn
    /> */}
    {/* <SearchInput
      placeholder="Company Name"
      source="companyName"
      resettable
      alwaysOn
    /> */}
  </Filter>
);

const AdminList = (props) => {
  return (
    <List {...props} filters={<AdminFilter />}>
      <Datagrid>
        <TextField source="id" label="ID" />
        <BooleanField source="isSuper" label="Super Admin" />
        <TextField source="user_ID" label="User ID" />
        <TextField source="user.first_Name" label="First Name" />
        <TextField source="user.email" label="Email" />
        {/* <TextField source="company_ID" label="Company ID" /> */}
        {/* <TextField source="company.companyName" label="Company Name" /> */}
        <DateField source="createdAt" label="Created At" />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

export default AdminList;
