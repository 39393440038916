import React, { useState, useEffect } from "react";
import Link from "@mui/material/Link";

const CustomFileReference = (props) => {
  const [referenceValue, setReferenceValue] = useState(props.value || "");

  // Synchronize internal state with external prop changes
  useEffect(() => {
    setReferenceValue(props.value || "");
  }, [props.value]);

  const { disabled } = props;

  return (
    <Link
      href={referenceValue}
      target="_blank"
      rel="noopener noreferrer"
      underline="always"
      style={{ cursor: disabled ? 'not-allowed' : 'pointer', color: disabled ? 'gray' : 'blue' }}
    >
      {props.placeholder || referenceValue || "No reference available"}
    </Link>
  );
};

export default CustomFileReference;